import { App, Form, Input, Modal } from 'antd';
import TextEditor from 'features/text-editor';
import { memo, useCallback } from 'react';
import { BulkEmail } from 'types';
import { useSendBulkEmailMutation } from './usersApi';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  selectedUsers: string[];
}

function BulkEmailModal({ isOpen, onCancel, selectedUsers }: Props) {
  // ** APIs
  const [send, { isLoading }] = useSendBulkEmailMutation();

  // ** Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  // ** Handlers
  const onFinish = useCallback(
    (values: BulkEmail) => {
      send({ ...values, users: selectedUsers })
        .unwrap()
        .then(({ message }) => {
          notification.success({
            message,
          });
          onCancel();
        });
    },
    [selectedUsers, notification, send, onCancel]
  );

  return (
    <Modal
      okButtonProps={{ htmlType: 'submit' }}
      confirmLoading={isLoading}
      title='إرسال بريد إلكتروني'
      open={isOpen}
      width={1024}
      onCancel={onCancel}
      modalRender={(modal) => (
        <Form
          initialValues={{
            subject: '',
            body: '',
          }}
          form={form}
          onFinish={onFinish}
          disabled={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name='bulk-email'
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name='subject' label='العنوان'>
        <Input />
      </Form.Item>
      <Form.Item name='body' label='محتوى الرسالة'>
        <TextEditor readOnly={isLoading} />
      </Form.Item>
    </Modal>
  );
}

export default memo(BulkEmailModal);
