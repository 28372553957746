import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { AUTH_COOKIE_NAME } from "common/constants";
import { IAuth, User } from "common/interfaces/interfaces";
import { getCookie, setCookie } from "common/utils/cookies";

const initialState: IAuth = {
  user: undefined,
  token: getCookie(AUTH_COOKIE_NAME),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<IAuth>) => {
      const { token } = action.payload;
      setCookie(AUTH_COOKIE_NAME, String(token));
      return { ...state, ...action.payload };
    },
    setAuthUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

export default authSlice.reducer;

export const { setAuth, setAuthUser } = authSlice.actions;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const selectAuthToken = (state: RootState) => state.auth.token;
