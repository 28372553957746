import { FormInstance } from 'antd';
import { Language } from 'common/interfaces/interfaces';
import {
  FEATURES,
  ServiceDto,
  ServiceOption,
  SERVICE_KEYS as KEYS,
} from '../services.types';

export function setFormFields({
  form,
  service,
  languages,
}: {
  form: FormInstance;
  service?: ServiceDto;
  languages: Language[];
}) {
  form.resetFields(['icon']);
  form.setFields([
    {
      name: KEYS.Key,
      value: service?.key ?? '',
    },
    {
      name: KEYS.Order,
      value: service?.order ?? null,
    },
    ...FEATURES.flatMap(({ key }) => {
      const field = service?.[key as keyof ServiceDto] as ServiceOption;
      return [
        {
          name: `${key}[${KEYS.IsEnabled}]`,
          value: field?.isEnabled ?? false,
        },
        {
          name: `${key}[${KEYS.Required}]`,
          value: field?.required ?? false,
        },
      ];
    }),
    ...languages.flatMap((lang, i) => {
      const displayNameTitle =
        service?.displayName.find((o) => o.languageId === lang.id)?.title ?? '';
      return [
        {
          name: `${KEYS.DisplayName}[${i}][${KEYS.Title}]`,
          value: displayNameTitle,
        },
        {
          name: `${KEYS.DisplayName}[${i}][${KEYS.LanguageId}]`,
          value: lang.id,
        },
        ...FEATURES.flatMap(({ key }) => {
          const field = service?.[key as keyof ServiceDto] as ServiceOption;
          const title =
            field?.titles.find((o) => o.languageId === lang.id)?.title ?? '';
          return [
            {
              name: `${key}[${KEYS.Titles}][${i}][${KEYS.LanguageId}]`,
              value: lang.id,
            },
            {
              name: `${key}[${KEYS.Titles}][${i}][${KEYS.Title}]`,
              value: title,
            },
          ];
        }),
      ];
    }),
  ]);
}
