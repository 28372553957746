import { ResponseWithMessage } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';
import { GetVisaResponse, GetVisasResponse } from './interfaces';

const visasAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVisas: builder.query<GetVisasResponse, void>({
      query: () => '/Visa/VisaAdminShow',
      providesTags: ['Visas'],
    }),
    getVisa: builder.mutation<GetVisaResponse, string>({
      query: (id) => ({
        url: `Visa/Get?Id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Visas'],
    }),
    addVisa: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'Visa/Add',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Visas'],
    }),
    editVisa: builder.mutation<
      ResponseWithMessage,
      { id: string; formData: FormData }
    >({
      query: (payload) => ({
        url: `Visa/Edit?Id=${payload.id}`,
        method: 'POST',
        body: payload.formData,
      }),
      invalidatesTags: ['Visas'],
    }),
    deleteVisa: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Visa/Delete?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Visas'],
    }),
    restoreVisa: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Visa/Restore?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Visas'],
    }),
  }),
});

export const {
  useGetVisasQuery,
  useAddVisaMutation,
  useGetVisaMutation,
  useEditVisaMutation,
  useDeleteVisaMutation,
  useRestoreVisaMutation,
} = visasAPI;
