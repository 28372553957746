import { Language } from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";

const localizationAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<Array<Language>, void>({
      query: () => "/Languages/GetAll",
    }),
  }),
});

export const { useGetLanguagesQuery } = localizationAPI;
