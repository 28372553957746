import { Button, Popconfirm, Table } from "antd";
import { ContactMessage } from "common/interfaces/interfaces";
import { memo, useCallback } from "react";
import { useGetNewMessagesQuery, useReadMessageMutation } from "./contactUsAPI";

export default memo(function NewMessages() {
  const { data, isFetching } = useGetNewMessagesQuery();
  const [readMessageMutation, { isLoading }] = useReadMessageMutation();

  const handleMessageClose = useCallback(
    (id: number) => {
      readMessageMutation(id);
    },
    [readMessageMutation]
  );

  return (
    <Table
      scroll={{ x: "max-content" }}
      loading={isFetching || isLoading}
      dataSource={data?.messages}
      bordered
      pagination={false}
      rowKey="id"
      columns={[
        {
          title: "م.",
          render: (_: any, __: any, i: number) => {
            return String(i + 1);
          },
        },
        {
          title: "الاسم",
          dataIndex: "name",
        },
        {
          title: "البريد الالكتروني",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "الهاتف",
          dataIndex: "phoneNumber",
        },
        {
          title: "الرسالة",
          dataIndex: "message",
        },
        {
          title: "توقيت الرسالة",
          dataIndex: "messageTime",
        },
        {
          title: "غلق الرسالة",
          render: (obj: ContactMessage) => (
            <Popconfirm
              title="تأكيد غلق الرسالة"
              onConfirm={() => handleMessageClose(obj.id)}
            >
              <Button danger>غلق</Button>
            </Popconfirm>
          ),
        },
      ]}
    />
  );
});
