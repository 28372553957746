import { Button, Form, Input, Modal, App, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import { useCallback, useEffect, useState } from "react";
import {
  FEATURES,
  FeaturesFields,
  ModalProps,
  ServiceDto,
  ServiceOption,
  SERVICE_KEYS as KEYS,
} from "../services.types";
import { useAddServiceMutation, useEditServiceMutation } from "../servicesAPI";
import FeaturesTable from "./FeaturesTable";
import LocalizationTabs from "./LocalizationTabs";
import { setFormFields } from "./Utils";

export default function ServiceModal({
  modelToEdit: service,
  languages,
  open,
  modalState,
  closeModal,
  ...props
}: ModalProps) {
  const { notification } = App.useApp();
  const [
    addServiceMutation,
    { isLoading: isAddLoading, isSuccess: isAddSuccess, data: addServiceData },
  ] = useAddServiceMutation();
  const [
    editServiceMutation,
    {
      isLoading: isEditLoading,
      isSuccess: isEditSuccess,
      data: editServiceData,
    },
  ] = useEditServiceMutation();
  const [form] = useForm();
  const [featuresState, setFeaturesState] = useState<FeaturesFields>(
    (function () {
      let state: FeaturesFields = {};
      FEATURES.forEach(({ key }) => {
        state[key] =
          (service?.[key as keyof ServiceDto] as ServiceOption)?.isEnabled ??
          false;
      });
      return state;
    })()
  );

  const isLoading = isAddLoading || isEditLoading;

  useEffect(() => {
    setFeaturesState(
      service
        ? Object.fromEntries(
            Object.entries(FEATURES).map(([_, { key }]) => {
              return [
                key,
                (service?.[key as keyof ServiceDto] as ServiceOption).isEnabled,
              ];
            })
          )
        : {}
    );

    setFormFields({ form, languages, service });
  }, [form, languages, open, service]);

  const handleOnOk = useCallback(() => form.submit(), [form]);

  const handleFormFinish = useCallback(
    (values: any) => {
      const formData = new FormData();
      Object.keys(values).map((k) => formData.append(k, values[k]));
      if (modalState === "edit" && service) {
        editServiceMutation({
          id: service.id,
          formData,
        });
      } else {
        addServiceMutation(formData);
      }
    },
    [addServiceMutation, editServiceMutation, modalState, service]
  );

  const normFile = useCallback((e: any) => e.file, []);

  useEffect(() => {
    if (isAddSuccess && addServiceData) {
      notification.success({ message: addServiceData.message });
      closeModal();
    }
  }, [isAddSuccess, addServiceData, closeModal, notification]);

  useEffect(() => {
    if (isEditSuccess && editServiceData) {
      notification.success({ message: editServiceData.message });
      closeModal();
    }
  }, [isEditSuccess, editServiceData, closeModal, notification]);

  return (
    <Modal
      confirmLoading={isLoading}
      width={1024}
      {...props}
      forceRender
      open={modalState !== undefined}
      onCancel={closeModal}
      onOk={handleOnOk}
      title={modalState === "add" ? "اضافة خدمة جديدة" : "تعديل بيانات خدمة"}
    >
      <Form
        disabled={isLoading}
        name="service-form"
        form={form}
        onFinish={handleFormFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name={KEYS.Icon}
          label="صورة للخدمة"
          valuePropName="file"
          getValueFromEvent={normFile}
        >
          <Upload beforeUpload={() => false} maxCount={1}>
            <Button>رفع</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name={KEYS.Key}
          label="كود تعريفي للخدمة"
          extra="يجب ان يكون باللغة الانجليزية بدون مسافات"
        >
          <Input />
        </Form.Item>
        <Form.Item name={KEYS.Order} label="ترتيب العرض">
          <Input type="number" />
        </Form.Item>

        <FeaturesTable setFeaturesState={setFeaturesState} />

        <LocalizationTabs languages={languages} featuresState={featuresState} />
      </Form>
    </Modal>
  );
}
