import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export const rtkQueryErrorHandler: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status, data } = action.payload;
    if (data?.errors) {
      const errors = Object.values(data.errors);

      toast.error(
        <ul className="m-0">
          {errors.map((msg, i) => (
            <li key={i}>{msg as string}</li>
          ))}
        </ul>
      );
    } else {
      switch (status) {
        case 401:
          toast.error("ليس لديك صلاحية !");
          break;
        default:
          toast.error("خطأ !");
      }
    }
  }
  return next(action);
};
