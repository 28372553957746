import { App, Form, Modal } from 'antd';
import { ResponseWithMessage } from 'common/interfaces/interfaces';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { UseMutation } from 'types';
import { FieldData } from 'rc-field-form/es/interface';

interface Props<C, U, F> {
  onClose: () => void;
  isOpen: boolean;
  children?: ReactNode;
  addMutation: UseMutation<C>;
  editMutation: UseMutation<U>;
  id?: number;
  handleCreateForm: (values: F) => C;
  handleUpdateForm: (values: F) => U;
  width?: string | number;
  fieldsToReset?: string[];
  fields: FieldData[];
}

export function UpsertDialog<C, U, F>({
  isOpen,
  onClose,
  children,
  addMutation,
  editMutation,
  id,
  handleCreateForm,
  handleUpdateForm,
  width,
  fields,
  fieldsToReset,
}: Props<C, U, F>) {
  //* APIs
  const [add, { isLoading: addIsLoading }] = addMutation();
  const [edit, { isLoading: editIsLoading }] = editMutation();

  //* Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  useEffect(() => {
    if (form) {
      form.resetFields(fieldsToReset);
      form.setFields(fields);
    }
  }, [form, fieldsToReset, fields]);

  //* Handlers
  const onSuccess = useCallback(
    (res: ResponseWithMessage) => {
      onClose();
      notification.success({ message: res?.message });
    },
    [notification, onClose]
  );

  const isEditMode = useMemo(() => !!id, [id]);

  const onFinish = useCallback(
    (values: F) => {
      if (!isEditMode) {
        add(handleCreateForm(values)).unwrap().then(onSuccess);
      } else {
        edit(handleUpdateForm(values)).unwrap().then(onSuccess);
      }
    },
    [add, onSuccess, edit, isEditMode, handleCreateForm, handleUpdateForm]
  );

  return (
    <Modal
      forceRender
      title={isEditMode ? 'تعديل بيانات' : 'اضافة بيان جديد'}
      onOk={form.submit}
      open={isOpen}
      okButtonProps={{ loading: addIsLoading || editIsLoading }}
      onCancel={onClose}
      width={width}
    >
      <Form
        form={form}
        onFinish={onFinish}
        name='upsert-dialog-form'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
      >
        {children}
      </Form>
    </Modal>
  );
}
