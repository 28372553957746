import { Form, Input, Tabs } from "antd";
import { Language } from "common/interfaces/interfaces";
import { Fragment, memo } from "react";
import {
  FEATURES,
  FeaturesFields,
  SERVICE_KEYS as KEYS,
} from "../services.types";

export default memo(function LocalizationTabs({
  languages,
  featuresState,
}: {
  featuresState: FeaturesFields;
  languages: Language[];
}) {
  return (
    <Tabs
      className="mt-3"
      centered
      type="card"
      defaultActiveKey={languages?.[0].code ?? undefined}
      items={languages?.map((lang, i) => ({
        forceRender: true,
        key: lang.code,
        label: lang.name,
        children: (
          <>
            <Form.Item
              name={`${KEYS.DisplayName}[${i}][${KEYS.Title}]`}
              label="عنوان الخدمة"
            >
              <Input placeholder={lang.name} />
            </Form.Item>
            <Form.Item
              name={`${KEYS.DisplayName}[${i}][${KEYS.LanguageId}]`}
              hidden
            >
              <Input />
            </Form.Item>
            {FEATURES.map(({ key, label }) => {
              return featuresState[key] === true ? (
                <Fragment key={key}>
                  <Form.Item
                    name={`${key}[${KEYS.Titles}][${i}][${KEYS.LanguageId}]`}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={`${key}[${KEYS.Titles}][${i}][${KEYS.Title}]`}
                    label={`عنوان ${label}`}
                  >
                    <Input />
                  </Form.Item>
                </Fragment>
              ) : null;
            })}
          </>
        ),
      }))}
    />
  );
});
