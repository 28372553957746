import { App, Form, Input, Modal } from 'antd';
import { useCallback } from 'react';
import { useSendNotificationsMutation } from './usersApi';
import { SendNotificationsModel } from 'types/SendNotificationsModel';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

export default function SendNotificationsModal({ isOpen, onCancel }: Props) {
  // ** APIs
  const [send, { isLoading }] = useSendNotificationsMutation();

  // ** Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  // ** Handlers
  const onFinish = useCallback(
    (values: SendNotificationsModel) => {
      send(values)
        .unwrap()
        .then(({ message }) => {
          notification.success({
            message,
          });
          onCancel();
          form.resetFields();
        });
    },
    [notification, send, onCancel, form]
  );

  return (
    <Modal
      okButtonProps={{ htmlType: 'submit' }}
      confirmLoading={isLoading}
      title='إرسال إشعارات'
      open={isOpen}
      width={768}
      onCancel={onCancel}
      modalRender={(modal) => (
        <Form
          initialValues={{
            title: '',
            body: '',
          }}
          form={form}
          onFinish={onFinish}
          disabled={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name='send-notifications'
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name='title' label='عنوان الرسالة'>
        <Input />
      </Form.Item>
      <Form.Item name='body' label='محتوى الرسالة'>
        <Input.TextArea rows={5} />
      </Form.Item>
    </Modal>
  );
}
