import { Button, Image, Table } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { useGetMoreServiceMutation, useGetMoreServicesQuery } from "./moreAPI";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import MoreServiceModal from "./MoreServiceModal";
import { IMoreModalView, IMoreService } from "./interfaces";
import IsVisible from "./IsVisible";

function MoreView() {
  const { data: languages } = useGetLanguagesQuery();
  const { data, isLoading: getServicesLoading } = useGetMoreServicesQuery();
  const [
    getService,
    {
      isLoading: getServiceLoading,
      isSuccess: getServiceSuccess,
      data: getServiceData,
    },
  ] = useGetMoreServiceMutation();
  const [serviceToEdit, setServiceToEdit] = useState<IMoreService>();
  const [modalView, setModalView] = useState<IMoreModalView>(undefined);

  const isLoading = getServiceLoading || getServicesLoading;

  useEffect(() => {
    if (modalView !== "edit") setServiceToEdit(undefined);
  }, [modalView]);

  useEffect(() => {
    if (getServiceData && getServiceSuccess) {
      setServiceToEdit(getServiceData.service);
      setModalView("edit");
    }
  }, [getServiceData, getServiceSuccess]);

  const getServiceToEdit = useCallback(
    (id: string) => {
      getService(id);
    },
    [getService]
  );

  const closeModal = useCallback(() => {
    setModalView(undefined);
  }, []);

  // const confirmDelete = async (id: string) => await deleteService(id);

  return (
    <>
      <Button onClick={() => setModalView("add")} className="mb-5">
        اضافة واجهة جديدة
      </Button>
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        bordered
        rowKey="id"
        dataSource={data?.services}
        columns={[
          {
            title: "م.",
            render: (_: any, __: any, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: "اسم الواجهة",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "صورة الواجهة",
            render: (obj: Partial<IMoreService>) => (
              <Image src={obj.icon} height={48} />
            ),
          },
          {
            title: "تعديل",
            render: (obj: Partial<IMoreService>) => (
              <Button onClick={() => getServiceToEdit(String(obj.id))}>
                تعديل
              </Button>
            ),
          },
          {
            title: "تفعيل الواجهة",
            render: (obj: IMoreService) => <IsVisible service={obj} />,
          },
        ]}
        pagination={false}
      />
      {languages && (
        <MoreServiceModal
          serviceToEdit={serviceToEdit}
          modalView={modalView}
          languages={languages}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

export default memo(MoreView);
