import { ResponseWithMessage, SubGroupModel } from 'common/interfaces';
import { apiSlice } from 'features/api/apiSlice';

export const subGroupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubGroups: builder.query<SubGroupModel[], void>({
      query: () => 'SubGroups/SubGroupsAdminShow',
      transformResponse(baseQueryReturnValue: { subGroups: SubGroupModel[] }) {
        return baseQueryReturnValue.subGroups;
      },
      providesTags: ['SubGroups'],
    }),

    createSubGroup: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'SubGroups/Add',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['SubGroups'],
    }),

    updateSubGroup: builder.mutation<
      ResponseWithMessage,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `SubGroups/Edit?Id=${id}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['SubGroups'],
    }),
    deleteSubGroup: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `SubGroups/Delete?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['SubGroups'],
    }),
    restoreSubGroup: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `SubGroups/Restore?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['SubGroups'],
    }),
    getSubGroup: builder.mutation<SubGroupModel, number>({
      query: (payload) => ({
        url: `SubGroups/Get?Id=${payload}`,
        method: 'POST',
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as { subGroup: SubGroupModel }).subGroup;
      },
    }),
  }),
});
