import { Button, Image, Table } from "antd";
import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  useGetInstituteMutation,
  useGetInstitutesQuery,
} from "./institutesAPI";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import InstituteModal from "./InstituteModal";
import IsVisible from "./IsVisible";
import { Institute, InstituteModalView } from "./interfaces";

const InstitutesView: FC = () => {
  const { data: languages } = useGetLanguagesQuery();
  const { data, isLoading: getServicesLoading } = useGetInstitutesQuery();
  const [
    getInstitute,
    {
      isLoading: getInstituteLoading,
      isSuccess: getInstituteSuccess,
      data: getInstituteData,
    },
  ] = useGetInstituteMutation();
  const [instituteToEdit, setInstituteToEdit] = useState<Institute>();
  const [modalView, setModalView] = useState<InstituteModalView>(undefined);

  const isLoading = getInstituteLoading || getServicesLoading;

  useEffect(() => {
    if (modalView !== "edit") setInstituteToEdit(undefined);
  }, [modalView]);

  useEffect(() => {
    if (getInstituteData && getInstituteSuccess) {
      setInstituteToEdit(getInstituteData.institute);
      setModalView("edit");
    }
  }, [getInstituteData, getInstituteSuccess]);

  const getServiceToEdit = useCallback(
    (id: string) => {
      getInstitute(id);
    },
    [getInstitute]
  );

  const closeModal = useCallback(() => {
    setModalView(undefined);
  }, []);

  return (
    <>
      <Button
        onClick={() => setModalView("add")}
        className="mb-5"
        loading={!languages}
      >
        اضافة معهد جديد
      </Button>
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        bordered
        rowKey="id"
        dataSource={data?.institutes}
        columns={[
          {
            title: "م.",
            render: (_, __, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: "اسم المعهد",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "صورة المعهد",
            render: (obj: Institute) => <Image src={obj.bkImage} height={48} />,
          },
          {
            title: "تعديل",
            render: (obj: Institute) => (
              <Button onClick={() => getServiceToEdit(String(obj.id))}>
                تعديل
              </Button>
            ),
          },
          {
            title: "اظهار",
            render: (obj: Institute) => <IsVisible institute={obj} />,
          },
        ]}
        pagination={false}
      />
      {languages && (
        <InstituteModal
          modelToEdit={instituteToEdit}
          modalView={modalView}
          languages={languages}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default memo(InstitutesView);
