import { Form, Input } from 'antd';
import { Language } from 'common/interfaces/interfaces';
import { memo } from 'react';
import TextEditor from '../text-editor';

export default memo(function ConditionsAndTermsForm({
  onChange,
  fields,
  language,
  isLoading,
}: FormProps) {
  const [form] = Form.useForm();

  return (
    <Form
      disabled={isLoading}
      form={form}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name='languageId' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='title' label='العنوان'>
        <Input />
      </Form.Item>
      <Form.Item name='htmlContent' label='المحتوى'>
        <TextEditor
          readOnly={isLoading}
          placeholder={language?.name}
          isLTR={!language?.isRTL}
        />
      </Form.Item>
    </Form>
  );
});

interface FormProps {
  onChange: (fields: FieldData[]) => void;
  fields: FieldData[];
  language?: Language;
  isLoading: boolean;
}

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
}
