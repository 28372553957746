import IsAdmin from "./IsAdmin";
import IsActive from "./isActive";
import { Button, TableColumnsType } from "antd";
import { IUsersView, User } from "common/interfaces/interfaces";

export default function UsersTableColumns({
  setState,
}: {
  setState: React.Dispatch<React.SetStateAction<IUsersView>>;
}) {
  const columns: TableColumnsType<User> = [
    {
      title: "م.",
      render: (_: any, __: any, i: number) => {
        return String(i + 1);
      },
    },
    {
      title: "اسم المستخدم",
      dataIndex: "userName",
    },
    {
      title: "الاسم بالكامل",
      dataIndex: "fullName",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phoneNumber",
    },
    {
      title: "البريد الالكتروني",
      dataIndex: "email",
    },
    {
      title: "الدولة",
      render: (user: User) => user.country.name,
    },
    {
      title: "تأكيد رقم الهاتف",
      render: (user: User) => (user.phoneNumberConfirmed ? "✅" : "❌"),
    },
    {
      title: "تأكيد البريد الالكتروني",
      render: (user: User) => (user.emailConfirmed ? "✅" : "❌"),
    },
    {
      title: "صلاحية مدير نظام",
      render: (user: User) => <IsAdmin user={user} />,
    },
    {
      title: "تفعيل المستخدم",
      render: (user: User) => <IsActive user={user} />,
    },
    {
      title: "تعديل",
      render: (user: User) => (
        <Button.Group>
          <Button
            onClick={() => {
              setState((prev) => ({
                ...prev,
                user: user,
                modalView: "edit",
              }));
            }}
          >
            تعديل
          </Button>
          <Button
            onClick={() => {
              setState((prev) => ({
                ...prev,
                user: user,
                modalView: "resetPassword",
              }));
            }}
          >
            تغيير كلمة المرور
          </Button>
        </Button.Group>
      ),
    },
  ];
  return columns;
}
