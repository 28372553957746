import { Button } from 'antd';
import { ReactNode, memo } from 'react';

interface Props {
  content: ReactNode;
  onClick: () => void;
}

export const AddButton = memo(function AddButton({ content, onClick }: Props) {
  return (
    <Button onClick={onClick} type='primary' className='mb-4'>
      {content}
    </Button>
  );
});
