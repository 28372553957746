import { App, Switch } from "antd";
import { User } from "common/interfaces/interfaces";
import { memo, useCallback, useEffect } from "react";
import { useActivateUserMutation, useDeactivateUserMutation } from "./usersApi";

const IsActive = ({ user }: { user: User }) => {
  const { notification } = App.useApp();
  const [
    activateUserMutation,
    {
      isLoading: isActivatingLoading,
      data: activatedRes,
      isSuccess: isActivateSuccess,
    },
  ] = useActivateUserMutation();
  const [
    deactivateUserMutation,
    {
      isLoading: isDeactivatingLoading,
      data: deactivateRes,
      isSuccess: isDeactivateSuccess,
    },
  ] = useDeactivateUserMutation();

  useEffect(() => {
    if (isActivateSuccess) {
      notification.success({ message: activatedRes?.message });
    }
  }, [isActivateSuccess, activatedRes, notification]);

  useEffect(() => {
    if (isDeactivateSuccess) {
      notification.success({ message: deactivateRes?.message });
    }
  }, [deactivateRes, isDeactivateSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        activateUserMutation(user.id);
      } else {
        deactivateUserMutation(user.id);
      }
    },
    [activateUserMutation, deactivateUserMutation, user.id]
  );

  return (
    <Switch
      defaultChecked={!user.isBlocked}
      loading={isActivatingLoading || isDeactivatingLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsActive);
