import { ResponseWithMessage } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';
import { Backup, BackupRestore } from './types';

const managementAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    takeBackup: builder.query<ResponseWithMessage, void>({
      query: () => ({
        url: 'Management/TakeBackup',
      }),
    }),
    deleteBackup: builder.query<ResponseWithMessage, number>({
      query: (id) => ({
        url: 'Management/DeleteBackup',
        params: { id },
      }),
    }),
    restoreBackup: builder.query<ResponseWithMessage, BackupRestore>({
      query: (payload) => ({
        url: 'Management/RestoreBackup',
        params: payload,
      }),
    }),
    restoreExternalBackup: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'Management/RestoreExternalBackUp',
        method: 'POST',
        body: payload,
      }),
    }),
    getBackups: builder.query<{ backups: Backup[] }, void>({
      query: () => ({
        url: 'Management/GetOldBackups',
      }),
      providesTags: ['Backups'],
    }),
    setTranslations: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'Management/SetTranslations',
        method: 'POST',
        body: payload,
      }),
    }),
    getTranslations: builder.query<{ excelFile: string }, void>({
      query: () => ({
        url: 'Management/GetTranslations',
      }),
    }),
  }),
});

export const {
  useLazyGetTranslationsQuery,
  useGetBackupsQuery,
  useLazyTakeBackupQuery,
  useLazyDeleteBackupQuery,
  useLazyRestoreBackupQuery,
  useSetTranslationsMutation,
  useRestoreExternalBackupMutation,
} = managementAPI;
