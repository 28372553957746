import React, { useCallback } from "react";
import { useLazyTakeBackupQuery } from "../management.api";
import { Button, App } from "antd";
import { useAppDispatch } from "app/hooks";
import { apiSlice } from "features/api/apiSlice";

export default function TakeBackup() {
  const { notification } = App.useApp();
  const dispatch = useAppDispatch();
  const [takeBackup, { isFetching }] = useLazyTakeBackupQuery();

  const onClick = useCallback(() => {
    takeBackup()
      .unwrap()
      .then((res) => {
        notification.success({ message: res.message });
        dispatch(apiSlice.util.invalidateTags(["Backups"]));
      });
  }, [takeBackup, dispatch, notification]);

  return (
    <Button onClick={onClick} loading={isFetching}>
      Take Backup
    </Button>
  );
}
