import { ResponseWithMessage } from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";
import { GetServiceDto, GetServicesDto } from "./services.types";

const servicesAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<GetServicesDto, void>({
      query: () => "/UserServices/UserServicesAdminShow",
      providesTags: ["Services"],
    }),
    getService: builder.mutation<GetServiceDto, string>({
      query: (id) => ({
        url: `/UserServices/Get?Id=${id}`,
        method: "POST",
      }),
    }),
    addService: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: "/UserServices/Add",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Services"],
    }),
    editService: builder.mutation<
      ResponseWithMessage,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `/UserServices/Edit?Id=${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Services"],
    }),
    deleteService: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `/UserServices/Delete?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["Institutes"],
    }),
    restoreService: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `/UserServices/Restore?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["Institutes"],
    }),
  }),
});

export const {
  useAddServiceMutation,
  useEditServiceMutation,
  useGetServicesQuery,
  useGetServiceMutation,
  useDeleteServiceMutation,
  useRestoreServiceMutation,
} = servicesAPI;
