import { Tabs } from 'antd';
import TakeBackup from './TakeBackUp';
import BackupsTable from './BackUpsTable';
import GetTranslations from './GetTranslations';
import SetTranslations from './SetTranslations';
import { RestoreExternal } from './RestoreExternal';

export default function SystemManagementView() {
  return (
    <Tabs
      items={[
        {
          label: 'Backups',
          key: 'backUps',
          children: (
            <div className='flex flex-col gap-5 items-start'>
              <div className='flex items-center gap-x-1'>
                <TakeBackup />
                <RestoreExternal />
              </div>

              <BackupsTable />
            </div>
          ),
        },
        {
          label: 'Translations',
          key: 'translations',
          children: (
            <>
              <GetTranslations />
              <SetTranslations />
            </>
          ),
        },
      ]}
    />
  );
}
