import { Button, Image, Table } from "antd";
import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  useGetTourismPackagesQuery,
  useGetTourismPackageMutation,
} from "./tourismPackagesAPI";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import TourismPackageModal from "./TourismPackageModal";
import IsVisible from "./IsVisible";
import { TourismPackage, TourismPackageModalView } from "./interfaces";

const TourismPackagesView: FC = () => {
  const { data: languages } = useGetLanguagesQuery();
  const { data, isLoading: getTourismPackagesLoading } =
    useGetTourismPackagesQuery();
  const [
    getTourismPackage,
    {
      isLoading: getTourismPackageLoading,
      isSuccess: getTourismPackageSuccess,
      data: getTourismPackageResponse,
    },
  ] = useGetTourismPackageMutation();
  const [tourismPackageToEdit, setTourismPackageToEdit] =
    useState<TourismPackage>();
  const [modalView, setModalView] =
    useState<TourismPackageModalView>(undefined);

  const isLoading = getTourismPackagesLoading || getTourismPackageLoading;

  useEffect(() => {
    if (modalView !== "edit") setTourismPackageToEdit(undefined);
  }, [modalView]);

  useEffect(() => {
    if (getTourismPackageSuccess) {
      setTourismPackageToEdit(getTourismPackageResponse?.package);
      setModalView("edit");
    }
  }, [getTourismPackageResponse, getTourismPackageSuccess]);

  const closeModal = useCallback(() => {
    setModalView(undefined);
  }, []);

  return (
    <>
      <Button
        onClick={() => setModalView("add")}
        className="mb-5"
        loading={!languages}
      >
        اضافة بيانات بكج سياحي
      </Button>
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        bordered
        rowKey="id"
        dataSource={data?.packages}
        columns={[
          {
            title: "م.",
            render: (_, __, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: "اسم الباكدج",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "صورة الباكدج",
            render: (obj: TourismPackage) => (
              <Image src={obj.bkImage} height={48} />
            ),
          },
          {
            title: "تعديل",
            render: (obj: TourismPackage) => (
              <Button onClick={getTourismPackage.bind(null, String(obj.id))}>
                تعديل
              </Button>
            ),
          },
          {
            title: "اظهار",
            render: (obj: TourismPackage) => <IsVisible tourismPackage={obj} />,
          },
        ]}
        pagination={false}
      />
      {languages && (
        <TourismPackageModal
          modelToEdit={tourismPackageToEdit}
          modalView={modalView}
          languages={languages}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default memo(TourismPackagesView);
