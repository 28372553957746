import { Tabs } from "antd";
import { memo } from "react";
import NewMessages from "./NewMessages";
import OldMessages from "./OldMessages";

function ContactMessagesView() {
  return (
    <Tabs
      items={[
        {
          label: "الرسائل الجديدة",
          key: "newMessages",
          children: <NewMessages />,
        },
        {
          label: "الرسائل المغلقة",
          key: "oldMessages",
          children: <OldMessages />,
        },
      ]}
    />
  );
}

export default memo(ContactMessagesView);
