import { App, Switch } from "antd";
import { memo, useCallback, useEffect } from "react";
import { useRestoreVisaMutation, useDeleteVisaMutation } from "./visasAPI";
import { Visa } from "./interfaces";

const IsVisible = ({ visa }: { visa: Visa }) => {
  // ** Hooks
  const { notification } = App.useApp();

  const [
    activateMutation,
    {
      isLoading: isActivateLoading,
      data: activateResponse,
      isSuccess: isActivateSuccess,
    },
  ] = useRestoreVisaMutation();
  const [
    deactivateMutation,
    {
      isLoading: isDeactivateLoading,
      data: deactivateResponse,
      isSuccess: isDeactivateSuccess,
    },
  ] = useDeleteVisaMutation();

  useEffect(() => {
    if (isActivateSuccess) {
      notification.success({ message: activateResponse?.message });
    }
  }, [isActivateSuccess, activateResponse, notification]);

  useEffect(() => {
    if (isDeactivateSuccess) {
      notification.success({ message: deactivateResponse?.message });
    }
  }, [deactivateResponse, isDeactivateSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        activateMutation(visa.id);
      } else {
        deactivateMutation(visa.id);
      }
    },
    [activateMutation, deactivateMutation, visa.id]
  );

  return (
    <Switch
      defaultChecked={visa.visible}
      loading={isActivateLoading || isDeactivateLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsVisible);
