import { Form, Select } from 'antd';
import { Country } from 'common/interfaces/interfaces';
import { useGetCountriesQuery } from 'features/countries/countriesApi';
import { useCallback } from 'react';

interface Props {
  worldWide?: boolean;
}

export function CountryFormItem({ worldWide }: Props) {
  const { isLoading: isGetCountriesLoading, data: countries } =
    useGetCountriesQuery({ worldWide: !!worldWide });

  const countriesFilterFunc = useCallback(
    (input: string, option: Country | undefined) =>
      option!.name.includes(input),
    []
  );

  return (
    <Form.Item name='country' label='الدولة'>
      <Select
        loading={isGetCountriesLoading}
        filterOption={countriesFilterFunc}
        showSearch
        options={countries}
        fieldNames={{ label: 'name', value: 'id' }}
      />
    </Form.Item>
  );
}
