import { Button, Divider, Form, Input, Select, Tabs, Upload } from 'antd';
import { CountryFormItem } from 'common/components';
import { groupsApi } from 'features/groups';
import { useGetLanguagesQuery } from 'features/localization';
import TextEditor from 'features/text-editor';
import { memo, useCallback } from 'react';

export const SubGroupFormContent = memo(function SubGroupFormContent() {
  //* APIs
  const { data: languages } = useGetLanguagesQuery();
  const { data: groups, isLoading: groupsIsLoading } =
    groupsApi.useGetGroupsQuery();

  const normFile = useCallback((e: any) => e.file, []);

  return (
    <>
      <Form.Item name='name' label='اسم القائمة'>
        <Input />
      </Form.Item>
      <Tabs
        defaultActiveKey={languages?.[0].code}
        items={languages?.map((lang, i) => ({
          forceRender: true,
          key: lang.code,
          label: lang.name,
          children: (
            <>
              <Form.Item name={`content[${i}][title]`} label='عنوان القائمة'>
                <Input
                  placeholder={lang.name}
                  style={{ direction: lang.isRTL ? 'rtl' : 'ltr' }}
                />
              </Form.Item>
              <Form.Item name={`content[${i}][content]`} label='المحتوى'>
                <TextEditor placeholder={lang.name} isLTR={!lang.isRTL} />
              </Form.Item>
              <Form.Item name={`name[${i}][languageId]`} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={`content[${i}][languageId]`} hidden>
                <Input />
              </Form.Item>
            </>
          ),
        }))}
      />
      <Divider />
      <Form.Item name='group' label='المجموعة الرئيسية'>
        <Select
          loading={groupsIsLoading}
          options={groups}
          fieldNames={{ label: 'title', value: 'id' }}
        />
      </Form.Item>
      <CountryFormItem worldWide={true} />
      <Form.Item
        name='bkImage'
        label='صورة'
        valuePropName='file'
        getValueFromEvent={normFile}
      >
        <Upload beforeUpload={() => false} maxCount={1} accept='image/*'>
          <Button>رفع</Button>
        </Upload>
      </Form.Item>
      <Form.Item name='order' label='ترتيب القائمة'>
        <Input type='number' min={1} />
      </Form.Item>
      <Form.Item name='hyperLink' label='الرابط'>
        <Input />
      </Form.Item>
    </>
  );
});
