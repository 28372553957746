import { GroupModel, ResponseWithMessage } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';

export const groupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addGroup: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'Groups/Add',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Group', 'Home'],
    }),
    getGroups: builder.query<GroupModel[], void>({
      query: () => '/Groups/GetAllGroups',
      transformResponse(baseQueryReturnValue: { groups: GroupModel[] }) {
        return baseQueryReturnValue.groups;
      },
      providesTags: ['Group'],
    }),
    deleteGroup: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Groups/Delete?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Group', 'Home'],
    }),
    restoreGroup: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Groups/Restore?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Group', 'Home'],
    }),
    getGroup: builder.mutation<GroupModel, number>({
      query: (payload) => ({
        url: `Groups/Get?Id=${payload}`,
        method: 'POST',
      }),
      transformResponse(baseQueryReturnValue) {
        return (baseQueryReturnValue as { group: GroupModel }).group;
      },
    }),
    editGroup: builder.mutation<
      ResponseWithMessage,
      { id: number; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `Groups/Edit?Id=${id}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Group', 'Home'],
    }),
  }),
});
