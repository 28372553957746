import { Modal, Form, FormInstance, Input, Select, App } from "antd";
import { FC, memo, useCallback, useEffect, useRef } from "react";
import { FieldData } from "rc-field-form/lib/interface";
import { Country, User } from "common/interfaces/interfaces";
import { useGetCountriesQuery } from "features/countries/countriesApi";
import { useEditUserMutation } from "./usersApi";

const UserEditModal: FC<{
  user: User;
  isOpen: boolean;
  closeModal: () => void;
}> = ({ closeModal, isOpen, user }) => {
  const { notification } = App.useApp();
  const { isLoading: isGetCountriesLoading, data: countries } =
    useGetCountriesQuery();
  const [editUser, { isLoading, data, isSuccess }] = useEditUserMutation();
  const formRef = useRef<FormInstance | null>(null);

  const onFormFinish = useCallback(
    (values: any) => editUser(values),
    [editUser]
  );

  useEffect(() => {
    formRef.current?.resetFields();
    if (formRef.current) {
      formRef.current.setFields([
        ...Object.keys(formRef.current?.getFieldsValue()).map(
          (key) =>
            ({
              name: key,
              value: user[key as keyof User],
            } as FieldData)
        ),
        {
          name: "country",
          value: user.country.id,
        },
        {
          name: "userId",
          value: user.id,
        },
      ]);
    }
  }, [isOpen, formRef, user]);

  const countriesFilterFunc = useCallback(
    (input: string, option: Country | undefined) =>
      option!.name.includes(input),
    []
  );

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: data?.message });
      closeModal();
    }
  }, [isSuccess, data, closeModal, notification]);

  return (
    <Modal
      confirmLoading={isLoading}
      okButtonProps={{ htmlType: "submit" }}
      title="تعديل بيانات مستخدم"
      open={isOpen}
      onCancel={closeModal}
      modalRender={(modal) => (
        <Form
          disabled={isLoading}
          onFinish={onFormFinish}
          ref={formRef}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="user-edit-form"
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name="userId" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="اسم المستخدم" name="userName">
        <Input autoComplete="new-password" autoFocus />
      </Form.Item>
      <Form.Item label="الاسم بالكامل" name="fullName">
        <Input autoComplete="new-password" autoFocus />
      </Form.Item>
      <Form.Item label="الهاتف" name="phoneNumber">
        <Input />
      </Form.Item>
      <Form.Item label="البريد الالكتروني" name="email">
        <Input type="email" autoComplete="new-password" />
      </Form.Item>
      <Form.Item name="country" label="الدولة">
        <Select
          loading={isGetCountriesLoading}
          filterOption={countriesFilterFunc}
          showSearch
          options={countries}
          fieldNames={{ label: "name", value: "id" }}
        />
      </Form.Item>
    </Modal>
  );
};

export default memo(UserEditModal);
