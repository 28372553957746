import { Button } from "antd";
import React, { useCallback } from "react";
import { useLazyGetTranslationsQuery } from "../management.api";

export default function GetTranslations() {
  const [getTranslations, { isFetching }] = useLazyGetTranslationsQuery();

  const onClickDownloadTranslations = useCallback(() => {
    getTranslations()
      .unwrap()
      .then((res) => {
        const link = document.createElement("a");
        link.href = res.excelFile;
        document.body.appendChild(link);

        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        document.body.removeChild(link);
      });
  }, [getTranslations]);

  return (
    <Button onClick={onClickDownloadTranslations} loading={isFetching}>
      Download Translations
    </Button>
  );
}
