import { Button, Image, Table } from "antd";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useGetVisaMutation, useGetVisasQuery } from "./visasAPI";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import VisaModal from "./VisaModal";
import IsVisible from "./IsVisible";
import { Visa, VisaModalView } from "./interfaces";

const VisasView: FC = () => {
  const { data: languages } = useGetLanguagesQuery();
  const { data, isLoading: getVisasLoading } = useGetVisasQuery();
  const [
    getVisa,
    {
      isLoading: getVisaLoading,
      isSuccess: getVisaSuccess,
      data: getVisaResponse,
    },
  ] = useGetVisaMutation();
  const [visaToEdit, setVisaToEdit] = useState<Visa>();
  const [modalView, setModalView] = useState<VisaModalView>(undefined);

  const isLoading = getVisaLoading || getVisasLoading;

  useEffect(() => {
    if (modalView !== "edit") setVisaToEdit(undefined);
  }, [modalView]);

  useEffect(() => {
    if (getVisaSuccess) {
      setVisaToEdit(getVisaResponse?.visa);
      setModalView("edit");
    }
  }, [getVisaResponse, getVisaSuccess]);

  const getVisaToEdit = useCallback(
    (id: string) => {
      getVisa(id);
    },
    [getVisa]
  );

  const closeModal = useCallback(() => {
    setModalView(undefined);
  }, []);

  return (
    <>
      <Button
        onClick={() => setModalView("add")}
        className="mb-5"
        loading={!languages}
      >
        اضافة بيانات تأشيرة
      </Button>
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        bordered
        rowKey="id"
        dataSource={data?.visas}
        columns={[
          {
            title: "م.",
            render: (_, __, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: "الدولة",
            dataIndex: "countryName",
            key: "countryName",
          },
          {
            title: "الاسم",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "صورة الدولة",
            render: (obj: Visa) => <Image src={obj.bkImage} height={48} />,
          },
          {
            title: "تعديل",
            render: (obj: Visa) => (
              <Button onClick={() => getVisaToEdit(String(obj.id))}>
                تعديل
              </Button>
            ),
          },
          {
            title: "اظهار",
            render: (obj: Visa) => <IsVisible visa={obj} />,
          },
        ]}
        pagination={false}
      />
      {languages && (
        <VisaModal
          modelToEdit={visaToEdit}
          modalView={modalView}
          languages={languages}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default memo(VisasView);
