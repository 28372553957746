import { ResponseWithMessage } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';
import {
  GetTourismPackageResponse,
  GetTourismPackagesResponse,
} from './interfaces';

const tourismPackagesAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTourismPackages: builder.query<GetTourismPackagesResponse, void>({
      query: () => '/Packages/PackageAdminShow',
      providesTags: ['Packages'],
    }),
    getTourismPackage: builder.mutation<GetTourismPackageResponse, string>({
      query: (id) => ({
        url: `Packages/Get?Id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Packages'],
    }),
    addTourismPackage: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: 'Packages/Add',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Packages'],
    }),
    editTourismPackage: builder.mutation<
      ResponseWithMessage,
      { id: string; formData: FormData }
    >({
      query: (payload) => ({
        url: `Packages/Edit?Id=${payload.id}`,
        method: 'POST',
        body: payload.formData,
      }),
      invalidatesTags: ['Packages'],
    }),
    deleteTourismPackage: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Packages/Delete?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Packages'],
    }),
    restoreTourismPackage: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Packages/Restore?Id=${payload}`,
        method: 'POST',
      }),
      invalidatesTags: ['Packages'],
    }),
  }),
});

export const {
  useGetTourismPackagesQuery,
  useGetTourismPackageMutation,
  useAddTourismPackageMutation,
  useEditTourismPackageMutation,
  useDeleteTourismPackageMutation,
  useRestoreTourismPackageMutation,
} = tourismPackagesAPI;
