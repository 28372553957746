import { useCallback } from "react";
import { useLazyDeleteBackupQuery } from "../management.api";
import { Button, Popconfirm, App } from "antd";
import { useAppDispatch } from "app/hooks";
import { apiSlice } from "features/api/apiSlice";

export default function DeleteBackup({ id }: { id: number }) {
  const dispatch = useAppDispatch();
  const { notification } = App.useApp();
  const [deleteBackup] = useLazyDeleteBackupQuery();

  const onConfirm = useCallback(
    () =>
      new Promise((resolve) => {
        deleteBackup(id)
          .unwrap()
          .then((res) => {
            notification.success({ message: res.message });
            dispatch(apiSlice.util.invalidateTags(["Backups"]));
          })
          .finally(() => resolve(null));
      }),

    [deleteBackup, dispatch, id, notification]
  );

  return (
    <Popconfirm
      title="تأكيد الحذف"
      onConfirm={onConfirm}
      okButtonProps={{ danger: true }}
    >
      <Button type="primary" danger>
        Delete
      </Button>
    </Popconfirm>
  );
}
