import { Modal, Form, Input, FormInstance, App } from 'antd';
import { CountryFormItem } from 'common/components';
import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { useAddUserMutation } from './usersApi';

const AddUserModal: FC<{
  isOpen: boolean;
  closeModal: () => void;
}> = ({ isOpen, closeModal }) => {
  const { notification } = App.useApp();
  const [addUser, { isLoading, data, isSuccess }] = useAddUserMutation();
  const formRef = useRef<FormInstance | null>(null);

  const onFormFinish = useCallback((values: any) => addUser(values), [addUser]);

  useEffect(() => {
    formRef.current?.resetFields();
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: data?.message });
      closeModal();
    }
  }, [isSuccess, data, closeModal, notification]);

  return (
    <Modal
      okButtonProps={{ htmlType: 'submit' }}
      title='اضافة مستخدم جديد'
      open={isOpen}
      onCancel={closeModal}
      confirmLoading={isLoading}
      modalRender={(modal) => (
        <Form
          onFinish={onFormFinish}
          disabled={isLoading}
          ref={formRef}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name='add-user-form'
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item label='اسم المستخدم' name='userName'>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label='الاسم بالكامل' name='fullName'>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label='الهاتف' name='phoneNumber'>
        <Input />
      </Form.Item>
      <Form.Item label='البريد الالكتروني' name='email'>
        <Input type='email' />
      </Form.Item>
      <CountryFormItem />
      <Form.Item label='كلمة المرور' name='password'>
        <Input.Password />
      </Form.Item>
      <Form.Item label='تأكيد كلمة المرور' name='confirmPassword'>
        <Input.Password />
      </Form.Item>
    </Modal>
  );
};

export default memo(AddUserModal);
