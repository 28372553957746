import { Dispatch, memo, useCallback, useRef, SetStateAction } from "react";
import { Button, Divider, Layout, Menu, MenuRef } from "antd";
import { AUTH_COOKIE_NAME } from "common/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { removeCookie } from "../../../common/utils/cookies";
import { setAuth } from "features/auth/authSlice";
import RoutesList from "common/RoutesList";

const { Sider } = Layout;

interface Props {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

function AppSider({ collapsed, setCollapsed }: Props) {
  const menuRef = useRef<MenuRef>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const logout = useCallback(() => {
    dispatch(setAuth({ token: undefined, user: null }));
    removeCookie(AUTH_COOKIE_NAME);
  }, [dispatch]);

  const onCollapse = useCallback(
    (value: boolean) => {
      setCollapsed(value);
    },
    [setCollapsed]
  );

  return (
    <Sider
      collapsed={collapsed}
      breakpoint="lg"
      collapsible
      onCollapse={onCollapse}
      collapsedWidth="0"
      theme="light"
      width={220}
      trigger={null}
    >
      <Menu
        ref={menuRef}
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        items={RoutesList.map((route) => ({
          ...route,
          children: route.children?.map((childRoute) => ({
            ...childRoute,
            onClick: () => navigate(childRoute.key),
          })),
          onClick: () => {
            if (!route.children) {
              navigate(route.key);
            }
          },
        }))}
      />
      <Divider />
      <Button
        className="mx-auto block text-xs px-3 h-7"
        type="dashed"
        onClick={logout}
        danger
      >
        تسجيل الخروج
      </Button>
    </Sider>
  );
}

export default memo(AppSider);
