import { Spin } from "antd";
import { memo } from "react";
import { useEffect } from "react";
import { removeCookie } from "common/utils/cookies";
import { AUTH_COOKIE_NAME } from "../../common/constants";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectAuthToken, setAuthUser } from "features/auth/authSlice";
import { useValidateMutation } from "features/auth/authApi";

function AppPreload() {
  const authToken = useAppSelector(selectAuthToken);
  const dispatch = useAppDispatch();

  const [validateMutation, { data, isError }] = useValidateMutation();

  useEffect(() => {
    if (isError) {
      dispatch(setAuthUser(null));
      removeCookie(AUTH_COOKIE_NAME);
    }
    if (data) dispatch(setAuthUser(data.user));
  }, [dispatch, isError, data]);

  useEffect(() => {
    if (authToken) {
      validateMutation();
    } else {
      dispatch(setAuthUser(null));
    }
  }, [dispatch, validateMutation, authToken]);

  return (
    <div className="flex h-screen w-screen justify-center items-center bg-slate-900">
      <Spin size="large" />
    </div>
  );
}

export default memo(AppPreload);
