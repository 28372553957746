import {
  ConditionsAndTerms,
  ConditionsAndTermsEditPayload,
  ResponseWithMessage,
} from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";

const conditionsAndTermsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConditionsAndTerms: builder.query<
      {
        termsAndConditions: Array<ConditionsAndTerms>;
      },
      void
    >({
      query: () => "/TermsAndConditions/GetAdminTC",
      providesTags: ["ConditionsAndTerms"],
    }),
    editConditionsAndTerms: builder.mutation<
      ResponseWithMessage,
      ConditionsAndTermsEditPayload[]
    >({
      query: (payload) => ({
        url: "/TermsAndConditions/Edit",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ConditionsAndTerms"],
    }),
  }),
});

export const {
  useGetConditionsAndTermsQuery,
  useEditConditionsAndTermsMutation,
} = conditionsAndTermsAPI;
