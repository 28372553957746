import { IResetPassword, ResponseWithMessage, User } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';
import { BulkEmail } from 'types';
import { SendNotificationsModel } from 'types/SendNotificationsModel';

const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<{ users: Array<User> }, void>({
      query: () => '/auth/getAll',
      providesTags: ['User'],
    }),
    activateUser: builder.mutation<{ message: string }, string>({
      query: (userId) => ({
        url: `/auth/activate?userId=${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    deactivateUser: builder.mutation<{ message: string }, string>({
      query: (userId) => ({
        url: `/auth/deactivate?userId=${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    setUserAdmin: builder.mutation<{ message: string }, string>({
      query: (userId) => ({
        url: `/auth/setAdmin?userId=${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    removeUserAdmin: builder.mutation<{ message: string }, string>({
      query: (userId) => ({
        url: `/auth/removeAdmin?userId=${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    userResetPassword: builder.mutation<{ message: string }, IResetPassword>({
      query: (payload) => ({
        url: `/auth/resetPassword?userId=${payload.userId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    addUser: builder.mutation<{ message: string }, any>({
      query: (payload) => ({
        url: '/auth/AdminRegisterUser',
        method: 'POST',
        body: { ...payload, acceptCondtions: true },
      }),
      invalidatesTags: ['User'],
    }),
    sendBulkEmail: builder.mutation<ResponseWithMessage, BulkEmail>({
      query: (body) => ({
        url: '/Auth/SendBulkMessage',
        method: 'POST',
        body,
      }),
    }),
    sendNotifications: builder.mutation<
      ResponseWithMessage,
      SendNotificationsModel
    >({
      query: (body) => ({
        url: '/Auth/SendBulkNotifications',
        method: 'POST',
        body,
      }),
    }),
    editUser: builder.mutation<{ message: string }, any>({
      query: (payload) => ({
        url: `/auth/editUserInfo?userId=${payload.userId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useSetUserAdminMutation,
  useRemoveUserAdminMutation,
  useUserResetPasswordMutation,
  useAddUserMutation,
  useEditUserMutation,
  useSendBulkEmailMutation,
  useSendNotificationsMutation,
} = usersApi;
