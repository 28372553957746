import {
  App,
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Modal,
  Tabs,
  Upload,
} from 'antd';
import { Language } from 'common/interfaces/interfaces';
import TextEditor from 'features/text-editor';
import { memo, useCallback, useEffect, useRef } from 'react';
import { IMoreModalView, IMoreService } from './interfaces';
import {
  useAddMoreServiceMutation,
  useEditMoreServiceMutation,
} from './moreAPI';

function MoreServiceModal({
  languages,
  modalView,
  closeModal,
  serviceToEdit,
}: {
  languages: Array<Language>;
  modalView: IMoreModalView;
  closeModal: () => void;
  serviceToEdit?: IMoreService;
}) {
  const { notification } = App.useApp();
  const [
    addService,
    { data: addRes, isSuccess: addSuccess, isLoading: addLoading },
  ] = useAddMoreServiceMutation();
  const [
    editService,
    { data: editRes, isSuccess: editSuccess, isLoading: editLoading },
  ] = useEditMoreServiceMutation();

  const isLoading: boolean = addLoading || editLoading;

  const formRef = useRef<FormInstance | null>(null);

  useEffect(() => {
    if (addSuccess) {
      closeModal();
      notification.success({ message: addRes?.message });
    }
  }, [addRes, addSuccess, closeModal, notification]);

  useEffect(() => {
    if (editSuccess) {
      closeModal();
      notification.success({ message: editRes?.message });
    }
  }, [editRes, editSuccess, closeModal, notification]);

  useEffect(() => {
    formRef.current?.resetFields(['icon']);

    formRef.current?.setFields([
      { name: 'name', value: serviceToEdit?.name ?? '' },
      { name: 'order', value: serviceToEdit?.order ?? '' },
      ...languages
        .map((l, i) => {
          const serviceContent = serviceToEdit?.content.find(
            (c) => c.languageId === l.id
          );
          return [
            {
              name: `content[${i}][languageId]`,
              value: l.id,
            },
            {
              name: `content[${i}][title]`,
              value: serviceContent?.title ?? '',
            },
            {
              name: `content[${i}][htmlContent]`,
              value: serviceContent?.htmlContent ?? '',
            },
          ];
        })
        .flat(),
    ]);
  }, [modalView, serviceToEdit, languages]);

  const onFormFinish = useCallback(
    (values: any) => {
      const formData = new FormData();
      Object.keys(values).map((k) => formData.append(k, values[k]));
      if (serviceToEdit) {
        editService({ id: String(serviceToEdit.id), formData });
      } else {
        addService(formData);
      }
    },
    [addService, editService, serviceToEdit]
  );

  const normFile = useCallback((e: any) => e.file, []);

  return (
    <Modal
      confirmLoading={isLoading}
      onCancel={closeModal}
      okButtonProps={{ htmlType: 'submit' }}
      open={modalView !== undefined}
      width={1024}
      title={modalView === 'add' ? 'اضافة واجهة جديدة' : 'تعديل بيانات واجهة'}
      modalRender={(modal) => (
        <Form
          disabled={isLoading}
          onFinish={onFormFinish}
          ref={formRef}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          name='more-service-form'
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name='name' label='اسم الواجهة'>
        <Input />
      </Form.Item>
      <Form.Item name='order' label='ترتيب الواجهة'>
        <Input type='number' />
      </Form.Item>
      <Form.Item
        name='icon'
        label='صورة الخدمة'
        valuePropName='file'
        getValueFromEvent={normFile}
      >
        <Upload beforeUpload={() => false} maxCount={1}>
          <Button>رفع</Button>
        </Upload>
      </Form.Item>
      <Divider />
      <Tabs
        defaultActiveKey={languages[0].code}
        items={languages.map((lang, i) => ({
          forceRender: true,
          key: lang.code,
          label: lang.name,
          children: (
            <>
              <Form.Item name={`content[${i}][title]`} label='العنوان'>
                <Input
                  placeholder={lang.name}
                  style={{ direction: lang.isRTL ? 'rtl' : 'ltr' }}
                />
              </Form.Item>
              <Form.Item name={`content[${i}][languageId]`} hidden>
                <Input />
              </Form.Item>
              <Form.Item name={`content[${i}][htmlContent]`} label='المحتوى'>
                <TextEditor placeholder={lang.name} isLTR={!lang.isRTL} />
              </Form.Item>
            </>
          ),
        }))}
      />
    </Modal>
  );
}

export default memo(MoreServiceModal);
