import { Checkbox, Form, Table } from "antd";
import { memo, SetStateAction, Dispatch } from "react";
import {
  FEATURES,
  FeaturesFields,
  SERVICE_KEYS as KEYS,
} from "../services.types";

export default memo(function FeaturesTable({
  setFeaturesState,
}: {
  setFeaturesState: Dispatch<SetStateAction<FeaturesFields>>;
}) {
  return (
    <Table
      scroll={{ x: "max-content" }}
      size="small"
      dataSource={FEATURES}
      columns={[
        { title: "اسم الخاصية", align: "center", dataIndex: "label" },
        {
          title: "تفعيل",
          align: "center",
          render: ({ key }) => (
            <Form.Item
              wrapperCol={{ span: 24 }}
              name={`${key}[${KEYS.IsEnabled}]`}
              valuePropName="checked"
              className="m-0"
            >
              <Checkbox
                onChange={(e) =>
                  setFeaturesState((prev) => ({
                    ...prev,
                    [key]: e.target.checked,
                  }))
                }
              />
            </Form.Item>
          ),
        },
        {
          title: "إجباري",
          align: "center",
          render: ({ key }) => (
            <Form.Item
              wrapperCol={{ span: 24 }}
              name={`${key}[${KEYS.Required}]`}
              valuePropName="checked"
              className="m-0"
            >
              <Checkbox />
            </Form.Item>
          ),
        },
      ]}
      bordered
      pagination={false}
    />
  );
});
