import { Button, Form, Input, App } from "antd";
import styles from "./login.module.scss";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "./logo.svg";
import { setAuth } from "../authSlice";
import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { useLoginMutation } from "../authApi";
import { LoginPayload } from "common/interfaces/interfaces";

export default function LoginView() {
  // ** Hooks
  const { notification } = App.useApp();
  const dispatch = useAppDispatch();

  // ** APIs
  const [loginMutation, { isLoading, data, isSuccess }] = useLoginMutation();

  const onFinish = async (values: LoginPayload) => {
    await loginMutation(values);
  };

  useEffect(() => {
    if (isSuccess && data?.user) {
      const { isAdmin, isActive } = data.user;
      if (isAdmin && isActive) {
        dispatch(setAuth(data));
      } else {
        notification.warning({ message: "ليس لديك صلاحية!" });
      }
    }
  }, [isSuccess, data, dispatch, notification]);

  return (
    <div
      className={[
        styles.content,
        "h-screen flex flex-col text-center items-center justify-center",
      ].join(" ")}
    >
      <div className="p-4 bg-black/20 max-w-md w-full">
        <Form onFinish={onFinish} size="large" className="bg-black/60 p-7">
          <img
            src={Logo}
            className="max-w-[18rem] mb-7"
            alt="Tashera"
            aria-label="logo"
          />
          <Form.Item name="input">
            <Input
              aria-label="username-input"
              prefix={<UserOutlined />}
              placeholder="اسم المستخدم"
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              aria-label="password-input"
              prefix={<LockOutlined />}
              placeholder="كلمة المرور"
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item className="m-0">
            <Button
              aria-label="login-submit"
              type="primary"
              htmlType="submit"
              className="!text-black"
              loading={isLoading}
            >
              تسجيل الدخول
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
