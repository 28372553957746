import { App, Switch } from "antd";
import { memo, useCallback, useEffect } from "react";
import { IMoreService } from "./interfaces";
import {
  useRestoreMoreServiceMutation,
  useDeleteMoreServiceMutation,
} from "./moreAPI";

const IsVisible = ({ service }: { service: IMoreService }) => {
  const { notification } = App.useApp();
  const [
    activateMutation,
    {
      isLoading: isActivateLoading,
      data: activateResponse,
      isSuccess: isActivateSuccess,
    },
  ] = useRestoreMoreServiceMutation();
  const [
    deactivateMutation,
    {
      isLoading: isDeactivateLoading,
      data: deactivateResponse,
      isSuccess: isDeactivateSuccess,
    },
  ] = useDeleteMoreServiceMutation();

  useEffect(() => {
    if (isActivateSuccess) {
      notification.success({ message: activateResponse?.message });
    }
  }, [isActivateSuccess, activateResponse, notification]);

  useEffect(() => {
    if (isDeactivateSuccess) {
      notification.success({ message: deactivateResponse?.message });
    }
  }, [deactivateResponse, isDeactivateSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        activateMutation(service.id);
      } else {
        deactivateMutation(service.id);
      }
    },
    [activateMutation, deactivateMutation, service.id]
  );

  return (
    <Switch
      defaultChecked={service.visible}
      loading={isActivateLoading || isDeactivateLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsVisible);
