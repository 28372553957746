import { App, Switch } from "antd";
import { memo, useCallback, useEffect } from "react";
import { Service } from "./services.types";
import {
  useDeleteServiceMutation,
  useRestoreServiceMutation,
} from "./servicesAPI";

const IsVisible = ({ service }: { service: Service }) => {
  const { notification } = App.useApp();
  const { id, visible } = service;
  const [
    activateMutation,
    {
      isLoading: isActivateLoading,
      data: activateResponse,
      isSuccess: isActivateSuccess,
    },
  ] = useRestoreServiceMutation();
  const [
    deactivateMutation,
    {
      isLoading: isDeactivateLoading,
      data: deactivateResponse,
      isSuccess: isDeactivateSuccess,
    },
  ] = useDeleteServiceMutation();

  useEffect(() => {
    if (isActivateSuccess) {
      notification.success({ message: activateResponse?.message });
    }
  }, [isActivateSuccess, activateResponse, notification]);

  useEffect(() => {
    if (isDeactivateSuccess) {
      notification.success({ message: deactivateResponse?.message });
    }
  }, [deactivateResponse, isDeactivateSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        activateMutation(id);
      } else {
        deactivateMutation(id);
      }
    },
    [activateMutation, deactivateMutation, id]
  );

  return (
    <Switch
      defaultChecked={visible}
      loading={isActivateLoading || isDeactivateLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsVisible);
