import { TableColumnsType, Image } from 'antd';
import { memo, useCallback, useMemo } from 'react';
import { GroupModel } from 'common/interfaces/interfaces';
import { TableViewTemplate } from 'common/components';
import { GroupFormContent } from './GroupFormContent';
import { groupsApi } from '../groups.api';
import { useGetLanguagesQuery } from 'features/localization';

export const GroupsTableView = memo(function GroupsTableView() {
  //* APIs
  const { data: languages } = useGetLanguagesQuery();

  //* Vars
  const columns: TableColumnsType<GroupModel> = [
    {
      title: 'م.',
      width: 64,
      render: (_, __, i: number) => {
        return String(i + 1);
      },
    },
    {
      title: 'اسم القائمة',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'ترتيب القائمة',
      dataIndex: 'order',
      key: 'order',
      width: 128,
    },
    {
      title: 'صورة القائمة',
      render: (model: GroupModel) => <Image src={model?.icon} height={48} />,
    },
  ];

  const fieldsToReset = useMemo(() => ['icon'], []);

  //* Handlers
  const handleCreateForm = useCallback(
    (values: Record<string, string | Blob>) => {
      const formData = new FormData();

      Object.keys(values).map((k) => formData.append(k, values[k]));

      return formData;
    },
    []
  );

  const handleUpdateForm = useCallback(
    (values: Record<string, string | Blob>, id: number) => {
      const formData = new FormData();

      Object.keys(values).map((k) => formData.append(k, values[k]));

      return { formData: handleCreateForm(values), id };
    },
    [handleCreateForm]
  );

  const fieldsHandler = useCallback(
    (model?: GroupModel) => {
      return [
        { name: 'key', value: model?.key ?? Date.now() },
        { name: 'order', value: model?.order ?? '' },
        ...[
          ...(languages
            ?.map((l, i) => {
              const titles = model?.title;

              const loc = Array.isArray(titles)
                ? titles.find((c) => c.languageId === l.id)
                : undefined;

              return [
                {
                  name: `title[${i}][languageId]`,
                  value: loc?.languageId ?? l.id,
                },
                {
                  name: `title[${i}][title]`,
                  value: loc?.title ?? '',
                },
              ];
            })
            .flat() ?? []),
        ],
      ];
    },
    [languages]
  );

  return (
    <TableViewTemplate
      addButtonContent='اضافة قائمة جديدة'
      addMutation={groupsApi.useAddGroupMutation}
      editMutation={groupsApi.useEditGroupMutation}
      columns={columns}
      formContent={<GroupFormContent />}
      getDataQuery={groupsApi.useGetGroupsQuery}
      handleCreateForm={handleCreateForm}
      handleUpdateForm={handleUpdateForm}
      dialogWidth={768}
      restoreMutation={groupsApi.useRestoreGroupMutation}
      deleteMutation={groupsApi.useDeleteGroupMutation}
      getModelMutation={groupsApi.useGetGroupMutation}
      fieldsHandler={fieldsHandler}
      fieldsToReset={fieldsToReset}
    />
  );
});
