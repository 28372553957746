import { HomeMenuSettingsModel } from 'common/models';
import { apiSlice } from 'features/api/apiSlice';

export const homeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMenuSettings: builder.query<HomeMenuSettingsModel, void>({
      query: () => `/Home/GetMenuSettings`,
      providesTags: ['Home'],
    }),
    setMenuSettings: builder.mutation<void, HomeMenuSettingsModel>({
      query: (body) => ({
        url: '/Home/SetMenuSettings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Home'],
    }),
  }),
});
