import { App, Button, Form, Upload } from 'antd';
import { useCallback } from 'react';
import { useSetTranslationsMutation } from '../management.api';

export default function SetTranslations() {
  // ** APIs
  const [setTranslations, { isLoading }] = useSetTranslationsMutation();

  // ** Hooks
  const { notification } = App.useApp();

  // ** Handlers
  const onFinish = useCallback(
    (values: any) => {
      const formData = new FormData();
      Object.keys(values).map((k) => formData.append(k, values[k]));
      setTranslations(formData)
        .unwrap()
        .then((res) =>
          notification.success({
            message: res.message,
          })
        );
    },
    [setTranslations, notification]
  );

  const normFile = useCallback((e: any) => e.file, []);

  return (
    <Form onFinish={onFinish} className='mt-7'>
      <Form.Item
        name='ExcelFile'
        label='Excel File'
        valuePropName='file'
        getValueFromEvent={normFile}
      >
        <Upload beforeUpload={() => false} maxCount={1}>
          <Button>Pick file</Button>
        </Upload>
      </Form.Item>
      <Button htmlType='submit' type='primary' loading={isLoading}>
        Submit
      </Button>
    </Form>
  );
}
