import { Modal, Form, Input, FormInstance, App } from "antd";
import { IResetPassword } from "common/interfaces/interfaces";
import { FC, memo, useCallback, useEffect, useRef } from "react";
import { useUserResetPasswordMutation } from "./usersApi";

const ResetPasswordModal: FC<{
  isOpen: boolean;
  userId: string;
  closeModal: () => void;
}> = ({ isOpen, closeModal, userId }) => {
  const { notification } = App.useApp();
  const formRef = useRef<FormInstance | null>(null);
  const [resetPasswordMutation, { isLoading, isSuccess, data }] =
    useUserResetPasswordMutation();

  useEffect(() => {
    formRef.current?.resetFields();
    formRef.current?.setFieldValue("userId", userId);
  }, [userId, isOpen]);

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: data?.message });
      closeModal();
    }
  }, [isSuccess, data, closeModal, notification]);

  const onFormFinish = useCallback(
    (values: IResetPassword) => resetPasswordMutation(values),
    [resetPasswordMutation]
  );

  return (
    <Modal
      okButtonProps={{ htmlType: "submit" }}
      confirmLoading={isLoading}
      title="تغيير كلمة المرور"
      open={isOpen}
      onCancel={closeModal}
      modalRender={(modal) => (
        <Form
          onFinish={onFormFinish}
          ref={formRef}
          disabled={isLoading}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          name="reset-password-form"
        >
          {modal}
        </Form>
      )}
    >
      <Form.Item name="userId" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="كلمة المرور" name="password">
        <Input type="password" autoComplete="new-password" autoFocus />
      </Form.Item>
      <Form.Item label="تأكيد كلمة المرور" name="confirmPassword">
        <Input type="password" autoComplete="new-password" />
      </Form.Item>
    </Modal>
  );
};

export default memo(ResetPasswordModal);
