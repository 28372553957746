import { Table } from "antd";
import { memo } from "react";
import { useGetOldMessagesQuery } from "./contactUsAPI";

export default memo(function OldMessages() {
  const { data, isFetching } = useGetOldMessagesQuery();
  return (
    <Table
      loading={isFetching}
      dataSource={data?.messages}
      bordered
      pagination={false}
      rowKey="id"
      scroll={{ x: "max-content" }}
      columns={[
        {
          title: "م.",
          render: (_: any, __: any, i: number) => {
            return String(i + 1);
          },
        },
        {
          title: "الاسم",
          dataIndex: "name",
        },
        {
          title: "البريد الالكتروني",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "الهاتف",
          dataIndex: "phoneNumber",
        },
        {
          title: "الرسالة",
          dataIndex: "message",
        },
        {
          title: "توقيت الرسالة",
          dataIndex: "messageTime",
        },
      ]}
    />
  );
});
