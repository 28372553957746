import { Button } from 'antd';
import { useCallback, useMemo } from 'react';
import { UseMutation } from 'types';

interface Props<T> {
  id: number;
  onSuccess: (model: T) => void;
  getModelMutation: UseMutation<number, T>;
}

export const EditButton = function EditButton<T>({
  id,
  onSuccess,
  getModelMutation,
}: Props<T>) {
  //* API
  const [getGroup, { isLoading }] = getModelMutation();

  //* Handlers
  const onClickEdit = useCallback(() => {
    getGroup(id).unwrap().then(onSuccess);
  }, [getGroup, onSuccess, id]);

  return useMemo(
    () => (
      <Button onClick={onClickEdit} loading={isLoading}>
        تعديل
      </Button>
    ),
    [isLoading, onClickEdit]
  );
};
