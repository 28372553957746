import { Descriptions, Modal, Spin } from 'antd';
import { useGetUserRequestQuery } from './usersRequestsAPI';
import { UserRequestData, UserRequestDataField } from 'common/interfaces/interfaces';
import { Fragment } from 'react';

type Props = {
  id: number;
  onCancel: () => void;
};

export default function DetailsModal({ id, onCancel }: Props) {
  const { data, isFetching } = useGetUserRequestQuery(id);

  return (
    <Modal
      open
      width={640}
      title='تفاصيل طلب'
      onCancel={onCancel}
      footer={false}
    >
      {!isFetching && data !== undefined ? (
        <ModalContent data={data.request} />
      ) : (
        <div className='flex justify-center'>
          <Spin size='large' />
        </div>
      )}
    </Modal>
  );
}

function ModalContent({ data }: { data: UserRequestData }) {
  const {
    contentType,
    country,
    deliveryWay,
    fileUpload,
    institute,
    nationality,
    notes,
    pageCount,
  } = data;

  const selectedFields = [
    contentType,
    country,
    deliveryWay,
    institute,
    nationality,
    notes,
    pageCount,
  ].filter((field): field is UserRequestDataField => field !== undefined);

  return (
    <Descriptions bordered column={1}>
      {selectedFields.map(({ isEnabled, title, userValue }) => (
        <Fragment key={title}>
          {isEnabled ? (
            <Descriptions.Item label={title}>{userValue}</Descriptions.Item>
          ) : (
            false
          )}
        </Fragment>
      ))}
      {fileUpload?.isEnabled && (
        <Descriptions.Item label={fileUpload.title}>
          <ul className='p-0'>
            {fileUpload.userValue.map(({ id, path }, index) => (
              <li key={id} className='list-none'>
                <a
                  href={path}
                  target='_blank'
                  rel='noreferrer'
                  className='text-sky-500'
                >{`مرفق ${index + 1}`}</a>
              </li>
            ))}
          </ul>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
}
