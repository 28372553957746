import { useCallback } from "react";
import { Button, Form, Input, Popconfirm, Switch, App } from "antd";
import { BackupRestore } from "../types";
import { useLazyRestoreBackupQuery } from "../management.api";

interface Props {
  id: number;
}
export default function RestoreBackup({ id }: Props) {
  // ** Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm<BackupRestore>();

  // ** APIs
  const [restore] = useLazyRestoreBackupQuery();

  // ** Handlers
  const onConfirm = useCallback(
    () =>
      new Promise((resolve) => {
        restore(form.getFieldsValue())
          .unwrap()
          .then((res) => {
            notification.success(res);
          })
          .finally(() => resolve(null));
      }),
    [form, restore, notification]
  );

  return (
    <Popconfirm
      title="Restore"
      onConfirm={onConfirm}
      description={
        <Form form={form}>
          <Form.Item name="Id" hidden initialValue={id}>
            <Input />
          </Form.Item>
          <Form.Item
            name="DbRestore"
            valuePropName="checked"
            label="DB Restore"
            initialValue={true}
          >
            <Switch className="bg-gray-300" />
          </Form.Item>
          <Form.Item
            name="FilesRestore"
            valuePropName="checked"
            label="Files Restore"
            initialValue={false}
          >
            <Switch className="bg-gray-300" />
          </Form.Item>
        </Form>
      }
    >
      <Button type="primary">Restore</Button>
    </Popconfirm>
  );
}
