import { Table } from "antd";
import { useGetBackupsQuery } from "../management.api";
import DeleteBackup from "./DeleteBackUp";
import RestoreBackup from "./RestoreBackup";

export default function BackupsTable() {
  const { data, isFetching } = useGetBackupsQuery();
  return (
    <Table
      scroll={{ x: "max-content" }}
      className="w-full"
      loading={isFetching}
      dataSource={data?.backups}
      bordered
      pagination={false}
      rowKey="id"
      columns={[
        {
          title: "no.",
          render: (_: any, __: any, i: number) => {
            return String(i + 1);
          },
        },
        {
          title: "dateTime",
          dataIndex: "dateTime",
        },
        {
          title: "path",
          dataIndex: "path",
          render: (path) => (
            <a href={path} download>
              {path}
            </a>
          ),
        },
        {
          title: "takenBy",
          dataIndex: "takenBy",
        },
        {
          title: "actions",
          dataIndex: "id",
          render: (id: number) => (
            <div className="flex items-center gap-1">
              <RestoreBackup id={id} />
              <DeleteBackup id={id} />
            </div>
          ),
        },
      ]}
    />
  );
}
