import { App, Switch } from 'antd';
import {
  IWithId,
  IWithVisibility,
  ResponseWithMessage,
} from 'common/interfaces';
import { useCallback } from 'react';
import { UseMutation } from 'types';

interface Props<T extends IWithVisibility & IWithId> {
  model: T;
  restoreMutation: UseMutation<number>;
  deleteMutation: UseMutation<number>;
}

export function IsVisible<T extends IWithVisibility & IWithId>({
  model,
  restoreMutation,
  deleteMutation,
}: Props<T>) {
  //* Hooks
  const { notification } = App.useApp();

  //* APIs
  const [activateMutation, { isLoading: isActivateLoading }] =
    restoreMutation();
  const [deactivateMutation, { isLoading: isDeactivateLoading }] =
    deleteMutation();

  //* Handlers
  const onChange = useCallback(
    (val: boolean) => {
      const onSuccess = (res: ResponseWithMessage) =>
        notification.success({ message: res?.message });

      const id = model.id;

      if (val) {
        activateMutation(id).unwrap().then(onSuccess);
      } else {
        deactivateMutation(id).unwrap().then(onSuccess);
      }
    },
    [activateMutation, deactivateMutation, model, notification]
  );

  return (
    <Switch
      defaultChecked={model.visible}
      loading={isActivateLoading || isDeactivateLoading}
      onChange={onChange}
    />
  );
}
