import { memo, useCallback, useEffect, useState } from "react";
import { Button, Divider, Spin, Tabs, App } from "antd";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import {
  useEditConditionsAndTermsMutation,
  useGetConditionsAndTermsQuery,
} from "./conditionsAndTermsAPI";
import ConditionsAndTermsForm from "./ConditionsAndTermsForm";
import { ConditionsAndTermsEditPayload } from "common/interfaces/interfaces";

export default memo(function ConditionsAndTermsView() {
  // ** Hooks
  const { notification } = App.useApp();

  const { data, isFetching } = useGetConditionsAndTermsQuery();
  const { data: languages } = useGetLanguagesQuery();
  const [
    editConditionsAndTermsMutation,
    { data: editResponse, isSuccess: editSuccess, isLoading: editLoading },
  ] = useEditConditionsAndTermsMutation();
  const [formData, setFormData] = useState<{
    [key: string]: ConditionsAndTermsEditPayload;
  }>({});

  useEffect(() => {
    if (editSuccess && editResponse) {
      notification.success({ message: editResponse.message });
    }
  }, [editSuccess, editResponse, notification]);

  const handleOnSubmit = useCallback(() => {
    editConditionsAndTermsMutation(Object.values(formData));
  }, [formData, editConditionsAndTermsMutation]);

  useEffect(() => {
    if (languages && data) {
      languages.forEach((lang) => {
        const langData = data.termsAndConditions.find(
          (o) => o.langId === lang.id
        );
        setFormData((prev) => ({
          ...prev,
          [lang.id]: {
            languageId: lang.id,
            title: langData?.title,
            htmlContent: langData?.htmlContent,
          },
        }));
      });
    }
  }, [languages, data]);

  const formDataKeys = Object.keys(formData) ?? 0;
  const isLoading = isFetching || editLoading;

  return formDataKeys.length > 0 ? (
    <>
      <Tabs
        items={formDataKeys.map((key) => {
          const record = formData[key];
          const language = languages?.find((l) => l.id === Number(key));

          return {
            key: key,
            forceRender: true,
            label: language?.name,
            children: (
              <ConditionsAndTermsForm
                isLoading={isLoading}
                language={language}
                key={key}
                fields={Object.keys(record).map((k) => ({
                  name: [k],
                  value: record[k as keyof ConditionsAndTermsEditPayload],
                }))}
                onChange={(newFields) => {
                  setFormData((prev) => ({
                    ...prev,
                    [key]: newFields.reduce(
                      (a, v) => ({
                        ...a,
                        [typeof v.name === "object" ? v.name[0] : v.name]:
                          v.value,
                      }),
                      {}
                    ) as ConditionsAndTermsEditPayload,
                  }));
                }}
              />
            ),
          };
        })}
      />
      <Divider />
      <Button
        onClick={handleOnSubmit}
        type="primary"
        size="large"
        loading={isLoading}
      >
        حفظ
      </Button>
    </>
  ) : (
    <Spin />
  );
});
