import { ContactMessage } from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";

const contactUsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNewMessages: builder.query<{ messages: Array<ContactMessage> }, void>({
      query: () => "/ContactUs/NewMessages",
      providesTags: ["ContactUs"],
    }),
    getOldMessages: builder.query<{ messages: Array<ContactMessage> }, void>({
      query: () => "/ContactUs/OldMessages",
      providesTags: ["ContactUs"],
    }),
    readMessage: builder.mutation<void, number>({
      query: (id) => `/ContactUs/ReadMessage?id=${id}`,
      invalidatesTags: ["ContactUs"],
    }),
  }),
});

export const {
  useGetNewMessagesQuery,
  useGetOldMessagesQuery,
  useReadMessageMutation,
} = contactUsAPI;
