import {
  ServiceRequestActionName,
  ServiceRequestPricing,
  UserRequest,
  UserRequestData,
} from 'common/interfaces/interfaces';
import { CurrencyModel } from 'common/models';
import { apiSlice } from 'features/api/apiSlice';

const usersRequestsAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersRequests: builder.query<{ requests: UserRequest[] }, void>({
      query: () => ({
        url: '/ManageRequests/Search',
        method: 'POST',
        body: {},
      }),
      providesTags: ['ServiceRequests'],
    }),
    getUserRequest: builder.query<{ request: UserRequestData }, number>({
      query: (id) => ({
        url: `/ManageRequests/Get?id=${id}`,
        method: 'POST',
      }),
      providesTags: ['ServiceRequests'],
    }),
    pricingServiceRequest: builder.mutation<
      void,
      { id: number; data: ServiceRequestPricing }
    >({
      query: ({ data, id }) => ({
        url: '/ManageRequests/Pricing',
        method: 'POST',
        params: {
          id,
        },
        body: data,
      }),
      invalidatesTags: ['ServiceRequests'],
    }),
    rejectServiceRequest: builder.mutation<
      void,
      { id: number; rejectReason: string }
    >({
      query: ({ rejectReason, id }) => ({
        url: '/ManageRequests/Reject',
        method: 'POST',
        params: {
          id,
        },
        body: { rejectReason },
      }),
      invalidatesTags: ['ServiceRequests'],
    }),
    serviceRequestAction: builder.mutation<
      void,
      { id: number; action: ServiceRequestActionName }
    >({
      query: ({ action, id }) => ({
        url: `/ManageRequests/${action}`,
        method: 'POST',
        params: {
          id,
        },
      }),
      invalidatesTags: ['ServiceRequests'],
    }),

    getCurrencies: builder.query<CurrencyModel[], void>({
      query: () => '/ManageRequests/GetCurrencies',
      transformResponse(baseQueryReturnValue: { currencies: CurrencyModel[] }) {
        return baseQueryReturnValue.currencies;
      },
    }),
  }),
});

export const {
  useGetUserRequestQuery,
  useGetUsersRequestsQuery,
  usePricingServiceRequestMutation,
  useServiceRequestActionMutation,
  useRejectServiceRequestMutation,
  useGetCurrenciesQuery,
} = usersRequestsAPI;
