import { Layout } from "antd";
import { memo, useCallback, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AppRoute } from "../../../common/interfaces/interfaces";
import RoutesList from "../../../common/RoutesList";
import AppSider from "./AppSider";
import AppHeader from "./AppHeader";

const { Content } = Layout;

function MainLayout() {
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const toggleDrawer = useCallback(
    () => setSiderCollapsed((prev) => !prev),
    []
  );

  return (
    <Layout className="min-h-screen">
      <AppSider collapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <Layout>
        <AppHeader
          toggleDrawer={toggleDrawer}
          siderCollapsed={siderCollapsed}
        />
        <Content className="mx-4 mt-4">
          <div className="p-6 bg-white">
            <Routes>
              {RoutesList.map((route) =>
                route.children
                  ? route.children.map((route2) => renderRoute(route2))
                  : renderRoute(route)
              )}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default memo(MainLayout);

const renderRoute = (route: AppRoute) => (
  <Route path={route.key} element={route.element} key={route.key} />
);
