import { MouseEvent } from "react";
import { ModalProps as AntModalProps } from "antd";
import { Language } from "common/interfaces/interfaces";

export interface GetServiceDto {
  service: ServiceDto;
}

export interface FeaturesFields {
  [s: string]: boolean;
}

export enum SERVICE_KEYS {
  Icon = "icon",
  Key = "key",
  DisplayName = "displayName",
  Order = "order",
  FileUpload = "fileUpload",
  IsEnabled = "isEnabled",
  Required = "required",
  Titles = "titles",
  Title = "title",
  Visible = "visible",
  LanguageId = "languageId",
  ContentType = "contentType",
  Notes = "notes",
  Country = "country",
  DeliveryWay = "deliveryWay",
  PageCount = "pageCount",
  Nationality = "nationality",
  VisaType = "visaType",
  TravelingDate = "travelingDate",
  Institute = "institute",
}

export const FEATURES = [
  {
    key: SERVICE_KEYS.FileUpload,
    label: "المرفقات",
  },
  {
    key: SERVICE_KEYS.ContentType,
    label: "نوع الوثيقة",
  },
  {
    key: SERVICE_KEYS.Notes,
    label: "ملاحظات اضافية",
  },
  {
    key: SERVICE_KEYS.Country,
    label: "الدولة",
  },
  {
    key: SERVICE_KEYS.DeliveryWay,
    label: "التوصيل",
  },
  {
    key: SERVICE_KEYS.PageCount,
    label: "عدد الصفحات",
  },
  {
    key: SERVICE_KEYS.Nationality,
    label: "الجنسية",
  },
  {
    key: SERVICE_KEYS.VisaType,
    label: "نوع التأشيرة",
  },
  {
    key: SERVICE_KEYS.TravelingDate,
    label: "تاريخ السفر",
  },
  {
    key: SERVICE_KEYS.Institute,
    label: "معاهد",
  },
];

export type ServiceDto = Service & {
  contentType: ServiceOption;
  country: ServiceOption;
  deliveryWay: ServiceOption;
  displayName: LocalizedTitle[];
  [SERVICE_KEYS.FileUpload]: ServiceOption;
  nationality: ServiceOption;
  notes: ServiceOption;
  pageCount: ServiceOption;
  visaType: ServiceOption;
  travelingDate: ServiceOption;
  institute: ServiceOption;
};

export interface ServiceOption {
  isEnabled: boolean;
  required: boolean;
  titles: LocalizedTitle[];
}

interface LocalizedTitle {
  languageId: number;
  title: string;
}

export type ModalState = undefined | "add" | "edit";

export interface ModalProps extends AntModalProps {
  objToEdit?: any;
  modalState: ModalState;
  languages: Language[];
  closeModal: (e?: MouseEvent<HTMLElement>) => void;
  modelToEdit?: ServiceDto;
}

export interface GetServicesDto {
  services: Service[];
}

export interface Service {
  id: number;
  [SERVICE_KEYS.Icon]: string;
  [SERVICE_KEYS.DisplayName]: string;
  [SERVICE_KEYS.Order]: number;
  [SERVICE_KEYS.Visible]: boolean;
  [SERVICE_KEYS.Key]: string;
}
