import { Form, Input, Modal, App, Select } from 'antd';
import { ServiceRequestPricing } from 'common/interfaces/interfaces';
import { useCallback, useState } from 'react';
import {
  useGetCurrenciesQuery,
  usePricingServiceRequestMutation,
} from './usersRequestsAPI';

type Props = { onCancel: () => void; id: number };

export default function PricingModal({ onCancel, id }: Props) {
  // ** API
  const [pricing] = usePricingServiceRequestMutation();
  const { data: currencies, isLoading: currenciesLoading } =
    useGetCurrenciesQuery();

  // ** State
  const [loading, setLoading] = useState(false);

  // ** Hooks
  const [form] = Form.useForm();
  const { notification } = App.useApp();

  // ** handlers
  const onFinishForm = useCallback(
    (values: ServiceRequestPricing) => {
      setLoading(true);
      pricing({ id, data: values })
        .unwrap()
        .then(() => {
          notification.success({ message: 'تم التعديل بنجاح!' });
          onCancel();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [pricing, id, onCancel, notification]
  );

  return (
    <Modal
      open
      title='التكلفة'
      onCancel={onCancel}
      onOk={form.submit}
      okButtonProps={{ loading }}
    >
      <Form
        disabled={loading}
        name='service-request-pricing-form'
        form={form}
        onFinish={onFinishForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item name='currency' label='العملة'>
          <Select
            loading={currenciesLoading}
            options={currencies ?? []}
            fieldNames={{ label: 'name', value: 'id' }}
          />
        </Form.Item>
        <Form.Item name='requestPrice' label='تكلفة الخدمة'>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='discount' label='الخصم'>
          <Input type='number' />
        </Form.Item>
        <Form.Item name='deliveryFees' label='تكلفة التوصيل'>
          <Input type='number' />
        </Form.Item>
      </Form>
    </Modal>
  );
}
