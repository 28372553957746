import { memo, useCallback, useEffect, useState } from 'react';
import { homeApi } from './home.api';
import { App, Button, Switch, Table } from 'antd';
import { HomeGroupModel, HomeMenuSettingsModel } from 'common/models';

type IModel = HomeGroupModel & {
  isMainGroup: boolean;
};

export const HomeManagement = memo(function HomeManagement() {
  //* APIs
  const { data, isFetching } = homeApi.useGetMenuSettingsQuery();
  const [setSettings, { isLoading: setIsLoading }] =
    homeApi.useSetMenuSettingsMutation();

  const isLoading = setIsLoading || isFetching;

  //* State
  const [items, setItems] = useState<IModel[]>([]);

  //* Hooks
  const { notification } = App.useApp();

  useEffect(() => {
    if (data)
      setItems([
        ...(data?.mainGroups.map((o) => ({ ...o, isMainGroup: true })) ?? []),
        ...(data?.groups.map((o) => ({ ...o, isMainGroup: false })) ?? []),
      ]);
  }, [data]);

  //* Handlers
  const onToggleItem = useCallback(
    (model: IModel) => {
      const index = items.findIndex(
        ({ id, key }) => id === model.id && key === model.key
      );

      const newItem = { ...model, showInHomeMenu: !model.showInHomeMenu };

      if (index > -1) {
        setItems([
          ...items.slice(0, index),
          newItem,
          ...items.slice(index + 1),
        ]);
      }
    },
    [items]
  );

  const onClickSave = useCallback(() => {
    const settings = items.reduce<HomeMenuSettingsModel>(
      (acc, { isMainGroup, ...model }) => {
        if (isMainGroup) {
          acc.mainGroups.push(model);
        } else {
          acc.groups.push(model);
        }
        return acc;
      },
      { groups: [], mainGroups: [] }
    );

    setSettings(settings)
      .unwrap()
      .then(() => notification.success({ message: 'تم الحفظ بنجاح.' }));
  }, [items, notification, setSettings]);

  return (
    <>
      <Button
        className='mb-4'
        type='primary'
        onClick={onClickSave}
        loading={isLoading}
      >
        حفظ
      </Button>
      <Table
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        bordered
        rowKey={({ id, key }) => `${id}-${key}`}
        dataSource={items}
        columns={[
          {
            title: 'اسم القائمة',
            dataIndex: 'title',
          },
          {
            title: 'اظهار القائمة في الصفحة الرئيسية',
            render(model: IModel) {
              return (
                <Switch
                  checked={model.showInHomeMenu}
                  onChange={() => {
                    onToggleItem(model);
                  }}
                />
              );
            },
          },
        ]}
        pagination={false}
      />
    </>
  );
});
