import { IAuth, LoginPayload, User } from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";

const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    validate: builder.mutation<{ user: User }, void>({
      query: () => "/auth/validate",
    }),
    login: builder.mutation<IAuth, LoginPayload>({
      query: (payload) => ({
        url: "/auth/login",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useLoginMutation, useValidateMutation } = authApi;
