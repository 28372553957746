import { Button, Table, Image } from "antd";
import { useGetLanguagesQuery } from "features/localization/localizationAPI";
import { useCallback, useEffect, useState } from "react";
import IsVisible from "./IsVisible";
import ServiceModal from "./service-modal/ServiceModal";
import { ModalState, Service, ServiceDto } from "./services.types";
import { useGetServiceMutation, useGetServicesQuery } from "./servicesAPI";

export default function ServicesView() {
  const { data: languages } = useGetLanguagesQuery();
  const { data, isLoading: getServicesIsLoading } = useGetServicesQuery();
  const [modalState, setModalState] = useState<ModalState>();
  const [
    getServiceMutation,
    {
      isLoading: getServiceLoading,
      isSuccess: getServiceSuccess,
      data: getServiceData,
    },
  ] = useGetServiceMutation();
  const [modelToEdit, setModelToEdit] = useState<ServiceDto>();

  const isLoading = getServiceLoading || getServicesIsLoading;

  const closeModal = useCallback(() => setModalState(undefined), []);

  useEffect(() => {
    if (getServiceData && getServiceSuccess) {
      setModelToEdit(getServiceData.service);
      setModalState("edit");
    }
  }, [getServiceData, getServiceSuccess]);

  useEffect(() => {
    if (modalState !== "edit") setModelToEdit(undefined);
  }, [modalState]);

  return (
    <>
      <Button
        onClick={() => setModalState("add")}
        className="mb-5"
        loading={!languages}
      >
        اضافة خدمة جديدة
      </Button>
      <Table
        scroll={{ x: "max-content" }}
        loading={isLoading}
        bordered
        rowKey="id"
        dataSource={data?.services}
        columns={[
          {
            title: "م.",
            render: (_, __, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: "اسم الخدمة",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "صورة الخدمة",
            render: (obj: Service) => <Image src={obj.icon} height={48} />,
          },
          {
            title: "تعديل",
            render: (obj: Service) => (
              <Button onClick={getServiceMutation.bind(null, String(obj.id))}>
                تعديل
              </Button>
            ),
          },
          {
            title: "اظهار",
            render: (obj: Service) => <IsVisible service={obj} />,
          },
        ]}
        pagination={false}
      />
      {languages && (
        <ServiceModal
          modelToEdit={modelToEdit}
          modalState={modalState}
          languages={languages}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
