import { forwardRef, memo, useCallback, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import EditorBuild from 'ckeditor5-custom-build/build/ckeditor';
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import type { EventInfo } from '@ckeditor/ckeditor5-utils';

interface Props {
  isLTR?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: (value: string) => unknown;
  value?: string;
}

const Editor = forwardRef<CKEditor<EditorBuild>, Props>(
  ({ isLTR, readOnly, placeholder, onChange, value }, ref) => {
    const editorConfig: EditorConfig = useMemo(
      () => ({
        mediaEmbed: {
          previewsInData: true,
        },
      }),
      []
    );

    if (placeholder) editorConfig.placeholder = placeholder;

    if (!isLTR) editorConfig.language = { content: 'ar', ui: 'ar' };

    const handleOnChange = useCallback(
      (_: EventInfo, editor: EditorBuild) => {
        onChange?.(editor.getData());
      },
      [onChange]
    );

    const onReady = useCallback(() => {
      const uploadButton = document
        .querySelector('.ck-file-dialog-button')
        ?.querySelector('button');
      uploadButton?.setAttribute('disabled', 'true');
    }, []);

    return (
      <CKEditor
        data={value}
        disableWatchdog={true}
        disabled={readOnly}
        editor={EditorBuild}
        config={editorConfig}
        onChange={handleOnChange}
        ref={ref}
        onReady={onReady}
      />
    );
  }
);

export default memo(Editor);
