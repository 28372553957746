import { memo, useEffect, useState } from "react";
import { Button, Col, Layout } from "antd";
import RoutesList from "common/RoutesList";
import { AppRoute } from "common/interfaces/interfaces";
import { useLocation } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Header } = Layout;

interface Props {
  siderCollapsed: boolean;
  toggleDrawer: () => void;
}

function AppHeader({ toggleDrawer, siderCollapsed }: Props) {
  const [pageTitle, setPageTitle] = useState<string | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    setPageTitle(findRoute(undefined, location.pathname)?.label);
  }, [location]);

  return (
    <Header className="bg-slate-900 text-white font-extrabold text-xl flex items-center gap-10">
      <Col lg={0}>
        <Button
          icon={
            siderCollapsed ? (
              <MenuFoldOutlined className="text-white !text-xl" />
            ) : (
              <MenuUnfoldOutlined className="text-white !text-xl" />
            )
          }
          onClick={toggleDrawer}
          type="text"
        />
      </Col>

      {pageTitle}
    </Header>
  );
}

export default memo(AppHeader);

const findRoute = (array = RoutesList, path: string) => {
  let result: AppRoute | undefined;
  array.some(
    (r) => (result = r.key === path ? r : findRoute(r.children || [], path))
  );
  return result;
};
