import { Table } from 'antd';
import { Dispatch, memo, useMemo } from 'react';
import { useGetAllUsersQuery } from './usersApi';
import UsersTableColumns from './UsersTableColumns';
import { IUsersView, User } from 'common/interfaces';
import { TableRowSelection } from 'antd/es/table/interface';

interface Props {
  setState: Dispatch<React.SetStateAction<IUsersView>>;
  setSelectedUsers: Dispatch<React.SetStateAction<User[]>>;
}

export const UsersTable = memo(function UsersTable({
  setState,
  setSelectedUsers,
}: Props) {
  const { data: usersRes, isLoading } = useGetAllUsersQuery();

  const columns = useMemo(
    () =>
      UsersTableColumns({
        setState,
      }),
    [setState]
  );

  const rowSelection: TableRowSelection<User> = useMemo(
    () => ({
      type: 'checkbox',
      onChange: function (_, selectedRows) {
        setSelectedUsers(selectedRows);
      },
    }),
    [setSelectedUsers]
  );

  return (
    <Table
      rowSelection={rowSelection}
      scroll={{ x: 'max-content' }}
      loading={isLoading}
      bordered
      dataSource={usersRes?.users}
      rowKey='id'
      columns={columns}
      pagination={false}
    />
  );
});
