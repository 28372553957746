import { ReactNode, useCallback, useState } from 'react';
import { AddButton } from './AddButton';
import { IWithId, IWithVisibility } from 'common/interfaces';
import { UpsertDialog } from './UpsertDialog';
import { UseMutation, UseQuery } from 'types';
import { Table, TableColumnsType } from 'antd';
import { EditButton } from './EditButton';
import { IsVisible } from './IsVisible';
import { FieldData } from 'rc-field-form/es/interface';

interface Props<T, C, U, F> {
  addButtonContent: ReactNode;
  addMutation: UseMutation<C>;
  editMutation: UseMutation<U>;
  getDataQuery: UseQuery<T>;
  formContent: ReactNode;
  handleCreateForm: (values: F) => C;
  handleUpdateForm: (values: F, id: number) => U;
  columns: TableColumnsType<T>;
  dialogWidth?: number;
  restoreMutation: UseMutation<number>;
  deleteMutation: UseMutation<number>;
  getModelMutation: UseMutation<number, T>;
  fieldsToReset?: string[];
  fieldsHandler: (model?: T) => FieldData[];
}

export function TableViewTemplate<
  T extends IWithId & IWithVisibility,
  C,
  U,
  F
>({
  getDataQuery,
  addButtonContent,
  addMutation,
  editMutation,
  formContent,
  handleCreateForm,
  handleUpdateForm,
  columns,
  dialogWidth,
  restoreMutation,
  deleteMutation,
  getModelMutation,
  fieldsToReset,
  fieldsHandler,
}: Props<T, C, U, F>) {
  //* APIs
  const { data, isFetching } = getDataQuery();

  //* State
  const [isModalOpen, setModalOpen] = useState(false);
  const [modelToEdit, setModelToEdit] = useState<T>();

  //* Handlers
  const onClickAdd = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setModalOpen(false);
    setModelToEdit(undefined);
  }, []);

  const onUpdateForm = useCallback(
    (values: F): U => {
      return handleUpdateForm(values, modelToEdit!.id);
    },
    [handleUpdateForm, modelToEdit]
  );

  const onSuccessEdit = useCallback((model: T) => {
    setModelToEdit(model);
    setModalOpen(true);
  }, []);

  const handleFields = useCallback(() => {
    return fieldsHandler(modelToEdit);
  }, [modelToEdit, fieldsHandler]);

  return (
    <>
      <AddButton content={addButtonContent} onClick={onClickAdd} />

      <UpsertDialog<C, U, F>
        addMutation={addMutation}
        editMutation={editMutation}
        isOpen={isModalOpen}
        onClose={onClose}
        id={modelToEdit?.id}
        fieldsToReset={fieldsToReset}
        fields={handleFields()}
        handleCreateForm={handleCreateForm}
        handleUpdateForm={onUpdateForm}
        width={dialogWidth}
      >
        {formContent}
      </UpsertDialog>

      <Table
        scroll={{ x: 'max-content' }}
        loading={isFetching}
        bordered
        rowKey='id'
        dataSource={data ?? []}
        columns={[
          ...columns,
          {
            title: 'تعديل',
            width: 128,
            render: (model: T) => (
              <EditButton
                id={model.id}
                onSuccess={onSuccessEdit}
                getModelMutation={getModelMutation}
              />
            ),
          },
          {
            title: 'اظهار',
            width: 128,
            render: (model: T) => (
              <IsVisible
                model={model}
                restoreMutation={restoreMutation}
                deleteMutation={deleteMutation}
              />
            ),
          },
        ]}
        pagination={false}
      />
    </>
  );
}
