import { memo, useCallback, useMemo } from 'react';
import { TableViewTemplate } from 'common/components';
import { subGroupsApi } from '../sub-groups.api';
import { SubGroupFormContent } from './SubGroupFormContent';
import { TableColumnsType, Image } from 'antd';
import { SubGroupModel } from 'common/interfaces';
import { useGetLanguagesQuery } from 'features/localization';

export const SubGroupsTableView = memo(function SubGroupsTableView() {
  //* APIs
  const { data: languages } = useGetLanguagesQuery();

  //* Vars
  const columns: TableColumnsType<SubGroupModel> = [
    {
      title: 'م.',
      width: 64,
      render: (_, __, i: number) => {
        return String(i + 1);
      },
    },
    {
      title: 'اسم القائمة',
      dataIndex: 'name',
    },
    {
      title: 'ترتيب القائمة',
      dataIndex: 'order',
      width: 128,
    },
    {
      title: 'صورة القائمة',
      render: (model: SubGroupModel) => (
        <Image src={model?.bkImage} height={48} />
      ),
    },
    {
      title: 'الدولة',
      dataIndex: 'countryName',
    },
    {
      title: 'اسم القائمة الرئيسية',
      dataIndex: 'groupName',
    },
    {
      title: 'الرابط',
      dataIndex: 'hyperLink',
    },
  ];

  const fieldsToReset = useMemo(() => ['bkImage'], []);

  //* Handlers
  const getFormData = useCallback((values: Record<string, string | Blob>) => {
    const formData = new FormData();

    Object.keys(values).map((k) => formData.append(k, values[k]));

    return formData;
  }, []);

  const handleUpdateForm = useCallback(
    (values: Record<string, string | Blob>, id: number) => {
      const formData = new FormData();

      Object.keys(values).map((k) => formData.append(k, values[k]));

      return { formData: getFormData(values), id };
    },
    [getFormData]
  );

  const fieldsHandler = useCallback(
    (model?: SubGroupModel) => {
      return [
        { name: 'name', value: model?.name ?? '' },
        { name: 'country', value: model?.country ?? '' },
        { name: 'order', value: model?.order ?? '' },
        { name: 'hyperLink', value: model?.hyperLink ?? '' },
        { name: 'group', value: model?.groupId },
        ...[
          ...(languages
            ?.map((l, i) => {
              const content = model?.content;
              const contentLoc = Array.isArray(content)
                ? content.find((c) => c.languageId === l.id)
                : undefined;

              return [
                {
                  name: `content[${i}][languageId]`,
                  value: contentLoc?.languageId ?? l.id,
                },
                {
                  name: `content[${i}][title]`,
                  value: contentLoc?.title ?? '',
                },
                {
                  name: `content[${i}][content]`,
                  value: contentLoc?.content ?? '',
                },
              ];
            })
            .flat() ?? []),
        ],
      ];
    },
    [languages]
  );

  return (
    <TableViewTemplate
      addButtonContent='اضافة قائمة جديدة'
      addMutation={subGroupsApi.useCreateSubGroupMutation}
      editMutation={subGroupsApi.useUpdateSubGroupMutation}
      columns={columns}
      formContent={<SubGroupFormContent />}
      getDataQuery={subGroupsApi.useGetSubGroupsQuery}
      handleCreateForm={getFormData}
      handleUpdateForm={handleUpdateForm}
      dialogWidth={1024}
      restoreMutation={subGroupsApi.useRestoreSubGroupMutation}
      deleteMutation={subGroupsApi.useDeleteSubGroupMutation}
      getModelMutation={subGroupsApi.useGetSubGroupMutation}
      fieldsHandler={fieldsHandler}
      fieldsToReset={fieldsToReset}
    />
  );
});
