import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "features/auth/authSlice";
import AppPreload from "features/root/AppPreload";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "features/root/layout/MainLayout";
import LoginView from "features/auth/login-view/LoginView";

export default function App() {
  const pathName = window.location.pathname;
  const authUser = useAppSelector(selectAuthUser);

  return authUser === undefined ? (
    <AppPreload />
  ) : (
    <Routes>
      {authUser && <Route element={<MainLayout />} path="/*" />}
      <Route
        path="/login"
        element={
          authUser ? (
            <Navigate to={pathName.includes("login") ? "/" : pathName} />
          ) : (
            <LoginView />
          )
        }
      />
      <Route
        path="*"
        element={<Navigate to={Boolean(authUser) ? "/" : "/login"} />}
      />
    </Routes>
  );
}
