import { ResponseWithMessage } from "common/interfaces/interfaces";
import { apiSlice } from "features/api/apiSlice";
import { GetInstituteResponse, GetInstitutesResponse } from "./interfaces";

const institutesAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInstitutes: builder.query<GetInstitutesResponse, void>({
      query: () => "/Institute/InstituteAdminShow",
      providesTags: ["Institutes"],
    }),
    getInstitute: builder.mutation<GetInstituteResponse, string>({
      query: (id) => ({
        url: `Institute/Get?Id=${id}`,
        method: "POST",
      }),
    }),
    addInstitute: builder.mutation<ResponseWithMessage, FormData>({
      query: (payload) => ({
        url: "Institute/Add",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Institutes"],
    }),
    editInstitute: builder.mutation<
      ResponseWithMessage,
      { id: string; formData: FormData }
    >({
      query: ({ id, formData }) => ({
        url: `Institute/Edit?Id=${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Institutes"],
    }),
    deleteInstitute: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Institute/Delete?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["Institutes"],
    }),
    restoreInstitute: builder.mutation<ResponseWithMessage, number>({
      query: (payload) => ({
        url: `Institute/Restore?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["Institutes"],
    }),
  }),
});

export const {
  useGetInstitutesQuery,
  useAddInstituteMutation,
  useGetInstituteMutation,
  useEditInstituteMutation,
  useDeleteInstituteMutation,
  useRestoreInstituteMutation,
} = institutesAPI;
