import { App, Switch } from "antd";
import { User } from "common/interfaces/interfaces";
import { memo, useCallback, useEffect } from "react";
import {
  useRemoveUserAdminMutation,
  useSetUserAdminMutation,
} from "./usersApi";

const IsAdmin = ({ user }: { user: User }) => {
  const { notification } = App.useApp();
  const [
    setUserAdminMutation,
    {
      isLoading: isSetAdminLoading,
      data: setAdminRes,
      isSuccess: isSetAdminSuccess,
    },
  ] = useSetUserAdminMutation();
  const [
    removeUserAdminMutation,
    {
      isLoading: isRemoveAdminLoading,
      data: removeAdminRes,
      isSuccess: isRemoveAdminSuccess,
    },
  ] = useRemoveUserAdminMutation();

  useEffect(() => {
    if (isSetAdminSuccess) {
      notification.success({ message: setAdminRes?.message });
    }
  }, [isSetAdminSuccess, setAdminRes, notification]);

  useEffect(() => {
    if (isRemoveAdminSuccess) {
      notification.success({ message: removeAdminRes?.message });
    }
  }, [removeAdminRes, isRemoveAdminSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        setUserAdminMutation(user.id);
      } else {
        removeUserAdminMutation(user.id);
      }
    },
    [setUserAdminMutation, removeUserAdminMutation, user.id]
  );

  return (
    <Switch
      defaultChecked={user.isAdmin}
      loading={isSetAdminLoading || isRemoveAdminLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsAdmin);
