import { useCallback, useState } from 'react';
import {
  useGetUsersRequestsQuery,
  useServiceRequestActionMutation,
} from './usersRequestsAPI';
import { Button, Dropdown, Space, Table, ButtonProps, App } from 'antd';
import { ServiceRequestActionName, UserRequest } from 'common/interfaces/interfaces';
import DetailsModal from './DetailsModal';
import { DownOutlined, ReloadOutlined } from '@ant-design/icons';
import PricingModal from './PricingModal';
import RejectModal from './RejectModal';

type ModalType = 'Details' | 'Pricing' | 'Reject';

export default function UsersRequestsView() {
  // ** Hooks
  const { notification } = App.useApp();

  // ** API
  const { data, isFetching, refetch } = useGetUsersRequestsQuery();
  const [action, { isLoading: actionIsLoading }] =
    useServiceRequestActionMutation();

  // ** States
  const [modalType, setModalType] = useState<ModalType>();
  const [selectedRequestId, setSelectedRequestId] = useState<number>();

  // ** handlers
  const onClickDetails = useCallback((id: number) => {
    setSelectedRequestId(id);
    setModalType('Details');
  }, []);

  const onClickAction = useCallback(
    (id: number, actionName: ServiceRequestActionName) => {
      action({ id, action: actionName })
        .unwrap()
        .then(() =>
          notification.success({
            message: 'تم التعديل بنجاح!',
          })
        );
    },
    [action, notification]
  );

  const onClickPricing = useCallback((id: number) => {
    setSelectedRequestId(id);
    setModalType('Pricing');
  }, []);

  const onClickReject = useCallback((id: number) => {
    setSelectedRequestId(id);
    setModalType('Reject');
  }, []);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
    setSelectedRequestId(undefined);
  }, []);

  // ** Vars
  const isLoading = isFetching || actionIsLoading;

  return (
    <>
      <Button
        icon={<ReloadOutlined />}
        type='dashed'
        className='mb-5'
        onClick={refetch}
      >
        تحديث
      </Button>
      <Table
        scroll={{ x: 'max-content' }}
        loading={isLoading}
        dataSource={data?.requests}
        bordered
        pagination={false}
        rowKey='id'
        columns={[
          {
            title: 'م.',
            render: (_: any, __: any, i: number) => {
              return String(i + 1);
            },
          },
          {
            title: 'الخدمة المطلوبة',
            render: ({ service }: UserRequest) => service.name,
          },
          {
            title: 'اسم المستخدم',
            render: (obj: UserRequest) => obj.requestUser.userName,
          },
          {
            title: 'رقم التواصل',
            render: (obj: UserRequest) => obj.requestUser.phoneNumber,
          },
          {
            title: 'توقيت الطلب',
            render: (obj: UserRequest) =>
              new Date(obj.requestDate).toLocaleString('ar-EG'),
          },
          {
            title: 'رقم الطلب',
            dataIndex: 'requestNumber',
          },
          {
            title: 'حالة الطلب',
            render: ({ requestStatus: { name } }: UserRequest) => name,
          },
          {
            title: 'تغيير حالة الطلب',
            render: ({
              actionsCanBeTaken,
              id: serviceRequestId,
            }: UserRequest) => {
              const allowedActions = actionsCanBeTaken?.filter(
                (action) => action.isEnabled
              );
              return allowedActions.length > 0 ? (
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: allowedActions.map(({ name, id: actionId, key }) => {
                      let props: ButtonProps = {};
                      switch (key) {
                        case 'Accept':
                          props = {
                            className: '!text-emerald-500',
                            onClick: onClickAction.bind(
                              null,
                              serviceRequestId,
                              'Accept'
                            ),
                          };
                          break;
                        case 'Reject':
                          props = {
                            className: '!text-red-500',
                            onClick: onClickReject.bind(null, serviceRequestId),
                          };
                          break;
                        case 'Pricing':
                          props = {
                            className: '!text-indigo-500',
                            onClick: onClickPricing.bind(
                              null,
                              serviceRequestId
                            ),
                          };
                          break;
                        case 'ReqInProg':
                          props = {
                            className: '!text-sky-500',
                            onClick: onClickAction.bind(
                              null,
                              serviceRequestId,
                              'RequestInProgress'
                            ),
                          };
                          break;
                        case 'DelInProg':
                          props = {
                            className: '!text-fuchsia-500',
                            onClick: onClickAction.bind(
                              null,
                              serviceRequestId,
                              'DeliveryInProgress'
                            ),
                          };
                          break;
                        case 'Delivered':
                          props = {
                            className: '!text-green-500',
                            onClick: onClickAction.bind(
                              null,
                              serviceRequestId,
                              'Delivered'
                            ),
                          };
                          break;
                      }
                      return {
                        key: actionId,
                        label: (
                          <Button
                            type='text'
                            style={{ fontWeight: 'bold' }}
                            {...props}
                          >
                            {name}
                          </Button>
                        ),
                      };
                    }),
                  }}
                >
                  <Button>
                    <Space />
                    تغيير حالة الطلب
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                false
              );
            },
          },
          {
            title: 'تفاصيل الطلب',
            render: ({ id }: UserRequest) => (
              <Button onClick={onClickDetails.bind(null, id)}>
                تفاصيل الطلب
              </Button>
            ),
          },
        ]}
      />
      {selectedRequestId &&
        (modalType === 'Details' ? (
          <DetailsModal id={selectedRequestId} onCancel={onCloseModal} />
        ) : modalType === 'Pricing' ? (
          <PricingModal id={selectedRequestId} onCancel={onCloseModal} />
        ) : (
          <RejectModal id={selectedRequestId} onCancel={onCloseModal} />
        ))}
    </>
  );
}
