import { apiSlice } from "features/api/apiSlice";
import { IMoreService } from "./interfaces";

const moreAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMoreServices: builder.query<{ services: Array<any> }, void>({
      query: () => "/More/MoreAdminServices",
      providesTags: ["More"],
    }),
    getMoreService: builder.mutation<{ service: IMoreService }, string>({
      query: (payload) => ({
        url: `More/Get?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["More"],
    }),
    addMoreService: builder.mutation<{ message: string }, FormData>({
      query: (payload) => ({
        url: "More/Add",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["More"],
    }),
    editMoreService: builder.mutation<
      { message: string },
      { id: string; formData: FormData }
    >({
      query: (payload) => ({
        url: `More/Edit?Id=${payload.id}`,
        method: "POST",
        body: payload.formData,
      }),
      invalidatesTags: ["More"],
    }),
    deleteMoreService: builder.mutation<{ message: string }, number>({
      query: (payload) => ({
        url: `More/Delete?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["More"],
    }),
    restoreMoreService: builder.mutation<{ message: string }, number>({
      query: (payload) => ({
        url: `More/Restore?Id=${payload}`,
        method: "POST",
      }),
      invalidatesTags: ["More"],
    }),
  }),
});

export const {
  useGetMoreServicesQuery,
  useAddMoreServiceMutation,
  useGetMoreServiceMutation,
  useEditMoreServiceMutation,
  useDeleteMoreServiceMutation,
  useRestoreMoreServiceMutation,
} = moreAPI;
