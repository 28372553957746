import { App, Button, Form, Modal, Switch, Upload } from 'antd';
import { FC, memo, useCallback, useState } from 'react';
import { useRestoreExternalBackupMutation } from '../management.api';

export const RestoreExternal: FC = memo(function () {
  //* API
  const [restore, { isLoading }] = useRestoreExternalBackupMutation();

  //* State
  const [isOpen, setOpen] = useState(false);

  //* Hooks
  const { notification } = App.useApp();
  const [form] = Form.useForm();

  //* Handlers
  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  const normFile = useCallback((e: any) => e.file, []);

  const onFinish = useCallback(
    (values: Record<string, string | Blob>) => {
      const formData = new FormData();

      Object.keys(values).map((k) => formData.append(k, values[k]));

      restore(formData)
        .unwrap()
        .then(() => {
          notification.success({ message: 'SUCCESS' });
          setOpen(false);
        })
        .catch(() => notification.error({ message: 'ERROR' }));
    },
    [restore, notification]
  );

  return (
    <>
      <Button onClick={onClick}>Restore External</Button>
      <Modal
        open={isOpen}
        forceRender
        title='Restore External'
        onOk={form.submit}
        width={512}
        onCancel={onCancel}
        okButtonProps={{ loading: isLoading }}
      >
        <Form
          initialValues={{
            DbRestore: true,
            FilesRestore: true,
          }}
          form={form}
          onFinish={onFinish}
          name='restore-external-form'
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          disabled={isLoading}
        >
          <Form.Item
            name='BackUpFile'
            label='BackUpFile'
            valuePropName='file'
            getValueFromEvent={normFile}
          >
            <Upload beforeUpload={() => false} maxCount={1} accept='.zip'>
              <Button>BackUpFile</Button>
            </Upload>
          </Form.Item>
          <Form.Item name='DbRestore' label='DbRestore' valuePropName='checked'>
            <Switch />
          </Form.Item>
          <Form.Item
            name='FilesRestore'
            label='FilesRestore'
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});
