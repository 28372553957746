import { Country } from 'common/interfaces/interfaces';
import { apiSlice } from 'features/api/apiSlice';

const countriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Array<Country>, Record<string, unknown> | void>(
      {
        query: (params) => ({
          url: '/countries/getAll',
          params: params ?? undefined,
        }),
      }
    ),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
