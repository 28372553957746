import { App, Switch } from "antd";
import { memo, useCallback, useEffect } from "react";
import {
  useRestoreInstituteMutation,
  useDeleteInstituteMutation,
} from "./institutesAPI";
import { Institute } from "./interfaces";

const IsVisible = ({ institute }: { institute: Institute }) => {
  const { notification } = App.useApp();
  const [
    activateMutation,
    {
      isLoading: isActivateLoading,
      data: activateResponse,
      isSuccess: isActivateSuccess,
    },
  ] = useRestoreInstituteMutation();
  const [
    deactivateMutation,
    {
      isLoading: isDeactivateLoading,
      data: deactivateResponse,
      isSuccess: isDeactivateSuccess,
    },
  ] = useDeleteInstituteMutation();

  useEffect(() => {
    if (isActivateSuccess) {
      notification.success({ message: activateResponse?.message });
    }
  }, [isActivateSuccess, activateResponse, notification]);

  useEffect(() => {
    if (isDeactivateSuccess) {
      notification.success({ message: deactivateResponse?.message });
    }
  }, [deactivateResponse, isDeactivateSuccess, notification]);

  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        activateMutation(institute.id);
      } else {
        deactivateMutation(institute.id);
      }
    },
    [activateMutation, deactivateMutation, institute.id]
  );

  return (
    <Switch
      defaultChecked={institute.visible}
      loading={isActivateLoading || isDeactivateLoading}
      onChange={onChange}
    />
  );
};

export default memo(IsVisible);
