import { Button, Divider, Form, Input, Tabs, Upload } from 'antd';
import { useGetLanguagesQuery } from 'features/localization';
import { memo, useCallback } from 'react';

export const GroupFormContent = memo(function GroupFormContent() {
  //* APIs
  const { data: languages } = useGetLanguagesQuery();

  //* Handlers
  const normFile = useCallback((e: any) => e.file, []);

  return (
    <>
      <Tabs
        defaultActiveKey={languages?.[0].code}
        items={languages?.map((lang, i) => ({
          forceRender: true,
          key: lang.code,
          label: lang.name,
          children: (
            <>
              <Form.Item name={`title[${i}][title]`} label='اسم القائمة'>
                <Input
                  placeholder={lang.name}
                  style={{ direction: lang.isRTL ? 'rtl' : 'ltr' }}
                />
              </Form.Item>
              <Form.Item name={`title[${i}][languageId]`} hidden>
                <Input />
              </Form.Item>
            </>
          ),
        }))}
      />
      <Divider />
      <Form.Item name='key' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name='icon'
        label='صورة'
        valuePropName='file'
        getValueFromEvent={normFile}
      >
        <Upload beforeUpload={() => false} maxCount={1} accept='image/*'>
          <Button>رفع</Button>
        </Upload>
      </Form.Item>
      <Form.Item name='order' label='ترتيب القائمة'>
        <Input type='number' min={1} />
      </Form.Item>
    </>
  );
});
